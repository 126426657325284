@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.my-result-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin-bottom: variables.$indentation-lg;

  .block-info-card {
    max-width: 400px;

    width: 100%;
    box-sizing: border-box;
    align-items: center;

    .block-info-card__content--vertical {
      .my-result-card {
        display: flex;
        flex-direction: column;
        gap: variables.$indentation-xsm;

        &__title {
          @include typography.subtitle2;
          color: colors.$upp-dark-content-primary;
        }

        &__description {
          @include typography.body;
          color: colors.$upp-dark-content-secondary;
        }
      }
    }

    &__icon {
      background: colors.$upp-light-blue !important;
    }

    button {
      height: 36px;
      width: auto;

      padding-left: variables.$indentation-xlg;
      padding-right: variables.$indentation-xlg;

      margin-bottom: 0;
    }
  }
}
