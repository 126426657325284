@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;
@use "@natera/theme/upp/colors";

.test-result {
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
}
