@use "@app/theme/variables";

.bubble-tail {
  position: absolute;
  bottom: 0;

  & svg {
    display: block;
  }

  &__received {
    left: -6px;
    transform: scaleX(-1);
    filter: drop-shadow(0px 1px 0px variables.$neva-text-bubble-shadow);
  }

  &__send-by-user {
    right: -6px;

    & svg {
      & path {
        fill: variables.$neva-sent-message;
      }
    }
  }
}
