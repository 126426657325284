@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/typography";

.resend-update-email__container {
  margin: 0 auto;
  text-align: center;
  max-width: 400px;
  padding: 0 variables.$indentation-lg;

  .image-container {
    margin: 20px 0 variables.$indentation-lg;
  }

  .description-container {
    h2 {
      @include typography.header2;
    }

    p {
      @include typography.body;
      margin: 0 0 variables.$indentation-lg 0;

      span {
        @include typography.subtitle2;
      }
    }
  }

  .buttons-container {
    button {
      width: 100%;
      margin-bottom: variables.$indentation-lg;
    }
  }
}
