.widget-main {
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
  scroll-behavior: smooth;

  &.without_scroll {
    overflow: hidden;
  }
}
