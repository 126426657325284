@use "@natera/theme/upp/_typography";
@use "@natera/theme/upp/_dimensions";
@use "@natera/theme/upp/_variables";
@use "@natera/theme/upp/_colors";

.update-email {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;

  @include dimensions.tablet {
    position: unset;
  }

  &-notification {
    margin-top: 8px;
  }

  .update-email__form::-webkit-scrollbar {
    display: none;
  }

  .update-email__form {
    height: 100%;
    padding: variables.$indentation-lg;
    background-color: colors.$upp-white;
    border-radius: 6px;
    margin: 0 0 variables.$indentation-sm 0;
    overflow-y: scroll;
    position: sticky;
    z-index: 1;
    max-height: calc(100vh - 180px);

    &:nth-child(2) {
      margin-top: -10px;
    }

    @include dimensions.tablet {
      margin: 0 variables.$indentation-xlg variables.$indentation-sm
        variables.$indentation-xlg;
    }

    @include dimensions.desktop {
      &:nth-child(2) {
        margin-top: -45px;
      }
    }

    &__title {
      @include typography.subtitle;
      margin: 20px 0 30px 0;
    }
    .notification__container {
      margin: variables.$indentation-lg 0;
      @include dimensions.tablet {
        margin: variables.$indentation-sm 0 variables.$indentation-xlg 0;
      }
    }

    .update-email-form {
      height: 100%;
      max-width: 500px;

      .form-content {
        padding: 0;

        .mdc-form-field.mdc-text-field {
          width: 100%;
        }

        .buttons-container {
          display: grid;
          grid-auto-flow: row;

          button {
            width: 100%;
          }

          button[type="submit"] {
            margin: variables.$indentation-lg 0;
          }

          @media (min-width: dimensions.$upp-mobile-max-width) {
            grid-auto-flow: column;
            gap: variables.$indentation-lg;

            button[type="submit"] {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.update-email-dialog {
  .update-email-dialog__container {
    display: flex;
    flex-direction: column;

    div {
      margin-bottom: variables.$indentation-lg;
      display: flex;

      h3 {
        margin: 0;
        padding-right: 5px;
        font-size: 20px;
        line-height: variables.$indentation-xlg;
      }

      .svg {
        height: variables.$indentation-xlg;
        width: variables.$indentation-xlg;
        cursor: pointer;
      }
    }

    p {
      @include typography.body;
      margin: 0 0 variables.$indentation-lg;
    }

    button:not(:last-child) {
      margin-bottom: variables.$indentation-lg;
    }
  }
}
