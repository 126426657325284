@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";

$buttonSize: 34px;

.video-stepper {
  position: relative;

  & > button {
    background-color: colors.$upp-blue;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - #{$buttonSize} / 2);

    @include mixins.mobile {
      width: $buttonSize;
      height: $buttonSize;
      padding: 5px;
    }
  }

  &__prev-btn {
    left: 4%;
  }

  &__next-btn {
    transform: rotate(180deg);
    right: 4%;
  }
}
