@use "@app/theme/_variables.scss" as variables;

.supplemental__button {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.03),
    0px 1px 7px rgba(0, 0, 0, 0.07);
  border-radius: 8px;

  min-height: 64px;
  max-width: 145px;

  cursor: pointer;

  .icon {
    flex: 0 0 38px;
    background-color: #faf6eb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;

    letter-spacing: 0.001em;
    overflow: hidden;
    color: variables.$black;
  }
}

.link-button:focus {
  border-radius: 8px;
}
