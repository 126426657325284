@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables" as natera-variables;

@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";

.step {
  background-color: colors.$upp-white;

  &__head {
    &-title {
      @include mixins.text-title;
    }
    &-subtitle {
      @include typography.subtitle2;
      color: variables.$upp-dark-grey;
      margin-top: natera-variables.$indentation-lg;
      margin-bottom: natera-variables.$indentation-sm;
    }
    &-text {
      @include mixins.text;
      color: variables.$upp-dark-grey;
      margin-top: 0;

      white-space: pre-line;
    }
  }

  &__content {
    margin-top: natera-variables.$indentation-lg;
    .form {
      .form-content {
        flex: 1 1 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        .form-field {
          .mdc-text-field {
            width: 100%;
          }
        }
      }

      .form-buttons {
        margin-top: natera-variables.$indentation-lg !important;
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: natera-variables.$indentation-lg;
        & > button {
          width: 100%;
        }
      }
    }
  }
}
