@use "@app/theme/variables";

.compassionate-dialog {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__text {
      padding-top: 8px;
      font-size: 10px;
      line-height: 12px;
      color: variables.$upp-input-border-color;
    }
  }
}
