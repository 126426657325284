$natera-blue-light: rgba(95, 164, 191, 0.12);
$natera-green: #8bc400;
$natera-light-green: #f0faec;
$natera-system-link: #59b4df;
$natera-green-light: rgba(127, 184, 111, 0.17);
$natera-orange: #f28f55;
$natera-orange-light: #eda769;
$natera-orange-lighter: rgba(237, 167, 105, 0.12);
$natera-red: #a3443d;
$natera-yellow: #e8c656;
$natera-gray-light: #f2f2f2;
$natera-gray: #a7a8aa;
$natera-gray-darker: #75787b;
$natera-gray-darker2: #656565;
$natera-gray-darkest: #1d252d;
$natera-border: #cccccc;
$natera-border-light: #ececee;
$natera-white: #ffffff;

$natera-system-warning: #e87722;
$natera-system-error: #b00020;
$natera-icon-grey: #b2b2b2;
$natera-secondary-yellow: #ffcd00;
$natera-secondary-blue: #0e4dc8;
$natera-secondary-purple: #863cff;

$blue-light: rgba(0, 139, 206, 0.08);
$gray-light: #ebebec;
$gray: #4c5359;
$gray-dark: #333a40;
$gray-darker: #1d252d;
$extra-light-gray-shadow: rgba(0, 0, 0, 0.1);
$light-gray-shadow: rgba(0, 0, 0, 0.25);
$gray-shadow: rgba(0, 0, 0, 0.3);
$natera-grey-border: rgba(0, 0, 0, 0.12);
$natera-border-radius: 4px;
$natera-base-padding: 24px;
$natera-additional-padding: 20px;
$color-navigaition: #425563;
$navigation-width: 70px;
$error: #cd292a;
$task-gray-medium: rgba(66, 75, 74, 0.32);
$task-gray-light: rgba(66, 75, 74, 0.12);
$test-result-hover: rgba(67, 156, 215, 0.2);
$red-dark: #b1001a;
$yellow: #f9c900;
$small-screen-max-width: 1200px;
$neutrals-low: rgba(167, 168, 170, 1);
$neutrals-medium: rgba(117, 120, 123, 1);
$neutrals-high: rgba(29, 37, 45, 1);
$black: #000000;
$dark-secondary: rgba(0, 0, 0, 0.64);
$neutrals-medium: rgba(117, 120, 123, 1);
$pagination-hover: rgba(67, 156, 215, 0.04);
$pagination-active: rgba(0, 0, 0, 0.2);

$upp-dark-content-color: rgba(0, 0, 0, 0.56);
$upp-notice-border-color: rgba(0, 0, 0, 0.12);
$upp-letter-spacing: 0.001em;
$upp-letter-spacing-description: 0.005em;
$upp-neutral-cream-color: #faf6eb;
$upp-system-info: #dbe9f3;
$upp-system-warning: #ffeccf;
$upp-system-error: #ffe2e2;
$upp-system-success: #d0ead0;
$upp-system-note: #faf6eb;
$upp-dark-grey: #404042;
$upp-description-text: #76787a;
$upp-primary-blue: #0f4b7c;
$upp-secondary-blue: #469ad4;
$upp-primary-green: #016601;
$upp-beige: rgba(255, 255, 255, 0.8);
$upp-light-beige: #fceedb;
$upp-dark-orange: #eeaa4b;
$upp-light-blue: #dbe9f3;
$upp-secondary-blue: #104777;
$upp-dark-blue: #1b4571;
$upp-red: #d6616e;
$upp-navigation-border: #e6e6e8;
$upp-red-light: rgba(214, 97, 110, 0.12);
$upp-transparent: rgba(0, 0, 0, 0);

$upp-blue-hover: rgba(67, 156, 215, 0.04);
$upp-blue-selected: rgba(232, 243, 250, 1);
$upp-neutral-blue: rgba(235, 241, 245, 1);
$upp-navy-blue-primary: #104777;

$upp-background-color: #f5f5f5;
$upp-gis-background-color: #faf6ec;

$upp-navigation-height: 56px;
$upp-input-border-color: rgba(0, 0, 0, 0.32);
$upp-gis-item-border-color: rgba(0, 0, 0, 0.03);
$upp-gis-item-border-bottom-color: rgba(0, 0, 0, 0.07);

$neva-main-text: #343333;
$neva-sent-message: #d9effe;
$neva-medium-gray: #b3b3b3;
$neva-primary-focus-state: #1782c9;
$neva-primary-click-state: #3f98d2;
$neva-secondary-click-state: #e1eff8;
$neva-secondary-focus-state: #f0f7fc;
$neva-secondary-text: #707070;
$neva-light-gray: #e5e5e5;
$neva-text-bubble-shadow: rgba(0, 0, 0, 0.04);
$neva-drop-shadow: rgba(0, 0, 0, 0.16);
$neva-scrollbar-overlay-shadow: rgba(24, 24, 24, 0.5);
$neva-widget-shadow: rgba(19, 69, 186, 0.086);
$neva-navy-blue-40: rgba(27, 69, 113, 0.4);
$neva-typing-40: rgba(150, 150, 150, 0.4);
$neva-typing-100: rgba(150, 150, 150, 1);
$neva-widget-overlay-background: rgba(219, 233, 243, 0.6);

$prebill-expansion-panel-border-color: #dbdbdb;
$prebill-payment-information-text-color: #004677;
$prebill-payment-information-border-color: #008bce;
$prebill-survey-info-box-bg-color: #f2f8fc;
$prebill-blue: #0377c4;

$upp-navigation-height: 56px;
$upp-desktop-width: 1920px;
$upp-laptop-width: 1280px;
$upp-tablet-width: 1024px;
$upp-mobile-width: 439px;

$upp-tablet-padding: 24px;
$upp-mobile-padding: 16px;
$upp-mobile-bottom-padding: #{$upp-navigation-height};

$upp-inner-box-max-width: 343px;

$upp-container-max-width: 896px;
$upp-tablet-container-max-width: 936px;

$indentation-xsm: 4px;
$indentation-sm: 8px;
$indentation-md: 12px;
$indentation-lg: 16px;
$indentation-xlg: 24px;
$indentation-xxlg: 32px;
