@use "@app/theme/_variables";
@use "@app/theme/_mixins";

.change-personal-information {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include mixins.from-laptop {
    max-height: 405px;
  }

  .change-personal-information__form {
    height: 100%;
    padding: 16px;
    background-color: variables.$natera-white;
    border-radius: 6px;
    margin: 0 variables.$upp-tablet-padding 8px variables.$upp-tablet-padding;

    &:nth-child(2) {
      margin-top: -45px;
    }

    @include mixins.tablet {
      margin: 0 0 8px 0;

      &:nth-child(2) {
        margin-top: -10px;
      }
    }

    &__title {
      margin: 0 0 30px 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
    .notification__container {
      margin: 8px 0 24px 0;
      @include mixins.tablet {
        margin: 16px 0;
      }
    }

    .change-personal-information-form {
      height: 100%;
      max-width: 500px;

      .form-content {
        padding: 0;

        .buttons-container {
          display: flex;
          flex-wrap: wrap;

          button {
            width: 100%;
          }

          button[type="submit"] {
            margin: 16px 0;
          }

          @media (min-width: variables.$upp-tablet-width) {
            flex-wrap: nowrap;
            justify-content: space-between;

            button {
              width: calc(50% - 8px);
            }

            button[type="submit"] {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
