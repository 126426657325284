@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/typography";

@use "@app/theme/_mixins";

.maintenance-header {
  position: fixed;
  top: 0;

  width: 100%;
  padding: 0 24px;

  .header__logo {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: dimensions.$upp-laptop-max-width;
    margin: 0 auto;
    padding: 16px 0;

    background-color: colors.$upp-white;
    border-bottom: 1px solid colors.$upp-dark-content-quaternary;

    @include mixins.tablet {
      margin: 0;
      border-bottom: none;
      height: unset;
    }

    @include mixins.mobile {
      margin: 0 auto;
      border-bottom: none;
      height: unset;
    }

    a {
      @include mixins.focus-visible;
      display: flex;
    }
  }
}

.maintenance {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-width: 400px;
  margin: 0 auto;

  i {
    margin: 84px auto 0;
  }

  h3 {
    @include typography.header3;
    margin: 24px auto;
    text-align: center;
  }

  &-subtitle {
    margin-top: 0;
    @include typography.bodyRegular;
    letter-spacing: 0.001em;
    text-align: center;
  }
}
