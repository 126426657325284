.message-container {
  &__wrap-container {
    box-sizing: border-box;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
  }
}
