@use "@natera/theme/upp/colors";
@use "@app/theme/mixins";

.nav-item {
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  cursor: pointer;

  &.nav-item--active {
    .link {
      .nav-item__icon {
        .svg > svg > * {
          fill-opacity: inherit;
          fill: colors.$upp-blue;
        }
      }

      .nav-item__text {
        color: colors.$upp-blue;
      }
    }
  }

  .link {
    display: inline-flex;
    text-decoration: none;
    align-items: center;
    width: 100%;

    @include mixins.focus-visible;

    .nav-item__icon {
      display: flex;
      align-items: center;
      height: 24px;
      width: 24px;
      margin-right: 25px;

      .svg {
        display: flex;
      }
    }

    .nav-item__text {
      color: colors.$upp-gray;
      line-height: 24px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0.001em;
      white-space: normal;
    }
  }
}

.mobile-navigation {
  .nav-item {
    @include mixins.mobile {
      justify-content: center;
    }

    .link {
      @include mixins.mobile {
        width: auto;
        flex-wrap: wrap;
        justify-content: center;
      }

      .nav-item__icon {
        @include mixins.mobile {
          display: block;
          width: 100%;
          margin: 0 0 4px 0;

          .svg {
            justify-content: center;
          }
        }
      }

      .nav-item__text {
        @include mixins.mobile {
          line-height: 12px;
          font-size: 10px;
        }
      }
    }
  }
}
