@use "@app/theme/mixins";
@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.simple-order-page {
  height: 100%;

  .flow-header__container {
    @include mixins.mobile {
      background-color: colors.$upp-white;
    }
  }
}

.stepper {
  &-page {
    &__container {
      position: relative;

      @include mixins.tablet {
        margin-right: 0 !important;
        padding-right: 24px !important;
      }

      @include mixins.mobile {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  &__head {
    &-title__container {
      display: flex;
      align-items: center;

      .stepper__head-title_text {
        @include mixins.text-title;
      }

      button.stepper__head-action_button {
        @include mixins.text;
        margin-left: auto;
        width: 84px;
      }
    }
  }

  &--hidden {
    .progress-stepper__header {
      display: none;
    }
  }

  &-edit_personal_info {
    background-color: none;

    .progress-stepper__header {
      display: none;
    }
  }
}
