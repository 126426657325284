.patient-review-dialog {
  & .dialog-container {
    text-align: center;
  }

  & .linkEdited {
    white-space: nowrap;
    display: inline-flex;
  }
}
