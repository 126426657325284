@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables" as natera-variables;

.oncology-dialog {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      .mdc-dialog__content {
        padding: 10px natera-variables.$indentation-lg
          natera-variables.$indentation-lg;
      }
    }
  }
}

.guest-mobile-phlebotomy-modal__container {
  text-align: left;

  p {
    @include typography.body;
    letter-spacing: 0.001em;
  }

  ul {
    @include typography.body;
    letter-spacing: 0.001em;
  }

  &--footer {
    margin-top: 42px;
    @include typography.body;
    text-align: center;

    p {
      margin: 0;
    }
  }
}
