@use "@natera/theme/upp/_variables";

.upp-form-field {
  &.upp-form-field--with-padding {
    padding-bottom: variables.$indentation-lg;

    &:last-child {
      padding: 0;
    }
  }
}
