@use "@app/theme/variables";

.password-notice__content {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  color: variables.$upp-dark-content-color;
  margin-right: 16px;

  & > .whitespace {
    min-height: 16px;
    width: 100%;
  }

  & > .password-notice__content__list {
    & > ul {
      margin: 0;
      padding-left: 0;

      & > li {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
      }
    }
    .password-notice__rule {
      color: variables.$upp-dark-content-color;
      list-style-type: none;
    }

    .password-notice__rule_invalid {
      color: black;
      list-style-type: none;
    }
  }

  .password-notice__content__list i {
    width: 12px;
    height: 12px;
    line-height: 12px;
    padding: 0 6px 0 2px;
    text-align: center;
  }

  .password-notice__title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0;
  }
}
