@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@app/theme/mixins";

@use "../common.scss";

.match-order {
  @include common.container;

  &-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-icon {
      margin-bottom: variables.$indentation-sm;
    }

    &-text {
      margin-top: variables.$indentation-sm;
    }
  }
}
