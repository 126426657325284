@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.billing-next-step {
  .step__head-text {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-primary;
    margin: 16px 0;
  }

  &__initial-section {
    &__title {
      @include typography.header2;
      color: colors.$upp-dark-content-primary;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 4px;
    }
    &__description {
      @include typography.bodyRegular;
      color: colors.$upp-dark-content-primary;
      text-align: center;
      margin-top: 4px;
    }
  }

  &__divider {
    background: colors.$upp-dark-content-quaternary;
    height: 1px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;

    display: none;
    @include mixins.mobile {
      display: block;
    }
  }

  .expansion-panel {
    &:nth-child(2) {
      border-top: none !important;
    }

    &:not(:nth-child(2)) {
      border-top: 1px solid variables.$prebill-expansion-panel-border-color;
    }

    &__content {
      padding: 0;
    }
  }

  .mdc-deprecated-list-item {
    padding-left: 0;
    padding-right: 0;
  }

  :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item
    .mdc-deprecated-list-item__ripple::before,
  :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item
    .mdc-deprecated-list-item__ripple::after {
    background-color: transparent;
    background-color: var(--mdc-ripple-color, transparent);
  }
}
