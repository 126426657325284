@use "@natera/theme/upp/components/material/button/mixins";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/typography";

.send-email-after-unlock {
  display: flex;
  flex-direction: column;
  height: 100%;

  .image-container {
    margin: 20px auto 0;
  }

  .description-container {
    h2 {
      @include typography.header2;
      text-align: center;
    }
    p {
      @include typography.body;
      letter-spacing: 0.001em;
      text-align: center;
      span {
        font-weight: 500;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .form.send-email-after-unlock-form {
    .form-buttons {
      display: flex;
      flex-direction: column;
      gap: variables.$indentation-lg;

      button {
        width: 100%;
      }
    }
  }

  p {
    margin-top: variables.$indentation-lg;
    @include typography.body;
    letter-spacing: 0.001em;
    text-align: center;
  }
}
