@use "@natera/theme/upp/colors";

.simple-order-stepper {
  .sub-stepper {
    .step__head {
      &-title,
      &-subtitle,
      &-text {
        color: colors.$upp-dark-content-primary;
      }
    }

    .step__content {
      .form-buttons {
        margin: 0;
        gap: 16px;

        button {
          margin: 0;
        }
      }
    }
  }
}
