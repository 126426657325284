@use "@app/theme/_variables.scss" as variables;
@use "@natera/theme/upp/colors";

.sign-up__container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  a {
    color: colors.$upp-navy-blue;
  }

  label {
    color: colors.$upp-gray !important;
  }

  .title__container {
    & > span {
      font-size: 14px;
      line-height: 18px;
    }

    & > h1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin: 0;
    }
  }

  .conditions__container {
    margin-top: 16px;
    font-size: 12px;
    line-height: 18px;
    color: colors.$upp-gray;
  }

  .separator__container {
    display: flex;
    align-items: center;
    margin: 24px 0;

    & > hr {
      width: 100%;
      border-left: none;
      border-right: none;
      border-bottom: none;
      border-top-color: rgba(0, 0, 0, 0.12);
    }

    & > span {
      padding: 0 16px;
      text-transform: uppercase;
      color: colors.$upp-gray;
    }
  }

  .form__container {
    display: flex;

    .form {
      flex-direction: inherit;
    }

    .form-content {
      & > button {
        margin-top: 8px;
      }
    }

    .email-field-notice__content {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 18px;
      color: colors.$upp-gray;

      & > .whitespace {
        min-height: 16px;
        width: 100%;
      }
    }

    .email-field-notice_hidden {
      height: 0;
      opacity: 0;
      margin-bottom: 0;
      border: 0;
    }
  }

  .to-sign-in__container {
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: colors.$upp-gray;
  }

  .acknowledgements__text {
    font-size: 12px;
  }

  a {
    font-weight: 400;
    text-decoration: underline;
  }
}
