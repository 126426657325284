@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.home {
  display: flex;
  flex-direction: column;

  &__container {
    margin: 24px 24px 24px 0;

    @include mixins.mobile {
      margin: variables.$indentation-lg;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &.no_active_tests_view {
      background-color: variables.$natera-white;
      height: 100%;
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &__test-cards {
    gap: variables.$upp-mobile-padding;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;

    @include mixins.mobile {
      display: flex;
      flex-direction: column;
      gap: unset;
      max-width: 600px;
    }

    &-column {
      gap: variables.$upp-mobile-padding;
      display: flex;
      flex-direction: column;

      @include mixins.tablet {
        gap: unset;
      }
    }
  }
}
