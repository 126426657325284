@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";

.self-pay-step {
  box-sizing: border-box;

  &__description {
    @include typography.body;
  }

  &__test-content {
    color: colors.$upp-dark-content-secondary;
    margin-bottom: 16px;
    &__title {
      @include typography.subtitle2;
      margin: 0;
      margin-bottom: 4px;
    }

    &__insurance {
      @include typography.body;
      margin: 0;
    }
  }

  &__price-estimation {
    color: colors.$upp-dark-content-secondary;
    @include typography.body;
  }

  .price-box {
    box-sizing: border-box;

    width: 100%;
    border-radius: variables.$border-radius-md;
    background: #f7f7f7;
    text-align: center;
    padding: 12px 16px;

    &__label {
      color: colors.$upp-blue;
      @include typography.header3;
    }
  }
}
