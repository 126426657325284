@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@app/theme/mixins";

@use "../common.scss";

.public-mp-personal-information {
  @include common.container;

  padding: 0;
  max-width: 600px;

  @media (min-width: dimensions.$upp-tablet-min-width) {
    padding: 0;
  }

  &-form__container {
    .form-field {
      .mdc-text-field {
        width: 100%;
      }
    }
  }

  &-stepper {
    border-radius: variables.$indentation-sm;

    @media (min-width: dimensions.$upp-tablet-min-width) {
      padding: variables.$indentation-xxlg !important;
      padding-top: 0 !important;
    }

    @media (min-width: dimensions.$upp-laptop-min-width) {
      padding: 56px !important;
      padding-top: 0 !important;
    }

    .mdc-linear-progress {
      @media (min-width: 601px) {
        border-top-left-radius: variables.$indentation-sm;
        border-top-right-radius: variables.$indentation-sm;
      }
    }
  }

  .progress-stepper__header {
    @media (max-width: dimensions.$upp-mobile-max-width) {
      margin: 0 -16px 16px;
    }

    @media (min-width: dimensions.$upp-tablet-min-width) {
      margin: 0 -32px 32px;
    }

    @media (min-width: dimensions.$upp-laptop-min-width) {
      margin: 0 -56px 56px;
    }
  }

  .stepper-form-buttons {
    @include common.actionButtons;
  }

  .confirmation-screen-form {
    .info-card {
      padding: 0;

      &-field {
        .display-field .info-card-field__value--text {
          @include typography.bodyRegular;
        }
      }
    }
  }
}

.notification__container {
  margin: variables.$indentation-lg 0;

  @include mixins.mobile {
    margin: variables.$indentation-lg 0;
  }
}
