@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";

.billing-updates-notification {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: colors.$upp-white;
  border: 1px solid colors.$upp-light-blue;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &__icon-container {
    width: 8px;
    height: 8px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__icon {
    width: 100%;
    height: 100%;
    background-color: colors.$upp-blue;
    border-radius: 50%;
    transition: opacity 0.3s ease;
  }

  &__content {
    flex-grow: 1;
  }

  &__text {
    @include typography.subtitle;
    color: colors.$upp-dark-content-primary;
    margin: 0 0 8px;
  }

  &__date {
    @include typography.body;
    color: colors.$upp-dark-content-secondary;
    margin: 0;
  }

  &--read {
    .billing-updates-notification__icon {
      opacity: 0;
    }
  }
}
