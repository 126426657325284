@use "@app/theme/variables";

.widget-iframe {
  &__container {
    position: absolute;
    top: 52px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 52px);
  }

  &__iframe {
    width: 100%;
    height: 100%;
    background-color: variables.$natera-white;
    border: none;
    transition: 0.3s ease-in-out;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    opacity: 0;

    &.show-iframe {
      opacity: 1;
    }
  }

  &__loader-container {
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: variables.$upp-input-border-color;
    border-radius: 0 0 10px 10px;
    z-index: 1;

    &.hide-loader {
      display: none;
    }
  }
}
