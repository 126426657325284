@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.cancel-simple-order-test-dialog-fail {
  &__content {
    @include typography.body;
    text-align: center;
    letter-spacing: 0.014px;
  }
}
