@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.launcher-popup__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-right: 8px;
}

.launcher-popup__close-icon-button {
  position: absolute;
  top: -22px;
  right: -22px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 44px;
  z-index: 1;
  cursor: pointer;
  background-color: transparent;
  border: none;

  &:focus-visible {
    outline: 2px solid colors.$upp-blue;
  }
}

.launcher-popup__close_icon {
  border: 2px solid colors.$upp-white;
  background: variables.$upp-dark-blue;
  border-radius: 50%;
  padding: 1px;
  box-shadow: 0px 2px 4px variables.$neva-drop-shadow;

  & svg {
    display: block;
  }
}

.launcher-popup__text-container {
  display: flex;
  background: variables.$upp-dark-blue;
  border-radius: 10px;
  animation: toggleOpacity 0.7s;
  cursor: pointer;
  padding: 8px;
  max-width: 224px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 2px 4px variables.$neva-drop-shadow;

  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 7px 0 7px 8px;
    border-color: transparent transparent transparent variables.$upp-dark-blue;
    right: -8px;
    bottom: 50%;
    transform: translateY(50%);
  }
}

.launcher-popup__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: colors.$upp-white;
  margin: 0;
}
