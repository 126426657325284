@use "@app/theme/variables";
@use "@app/theme/_mixins";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables" as natera-variables;

.custom-select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  align-items: center;
  background: colors.$upp-white;
  border: none;
  border-radius: natera-variables.$border-radius-xsm;
  box-shadow: 0px 0px 4px variables.$light-gray-shadow;
  cursor: pointer;
  display: flex;

  .mdc-select__anchor {
    width: initial;
    background-color: colors.$upp-white !important;

    .mdc-select__selected-text-container {
      padding: 0 !important;

      span.mdc-select__selected-text {
        color: colors.$upp-navy-blue !important;
        @include typography.buttonMedium;
      }
    }

    .mdc-select__dropdown-icon {
      margin-right: 5px;

      .mdc-select__dropdown-icon-inactive,
      .mdc-select__dropdown-icon-active {
        display: none;
      }
    }

    .mdc-select__dropdown-icon::after {
      position: absolute;
      right: 10px;
      top: 40%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .mdc-select__anchor[aria-expanded="true"] .mdc-select__dropdown-icon::after {
    content: url("@assets/svg/icons/up-arrow.svg");
  }

  .mdc-select__anchor:not([aria-expanded="true"])
    .mdc-select__dropdown-icon::after {
    content: url("@assets/svg/icons/down-arrow.svg");
  }

  .mdc-select__anchor:focus-visible {
    outline: 2px solid colors.$upp-navy-blue;
  }
}

.dropdown-icon {
  pointer-events: none;
  right: 12px;
  top: 40%;
  transform: translateY(-50%);
}
