@use "@app/theme/mixins";
@use "@app/theme/variables";

.partner-info-providing-dialog {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      max-width: calc(100vw - 32px);

      @media (min-width: variables.$upp-mobile-width) {
        max-width: 343px;
      }

      .mdc-dialog__actions {
        gap: 8px;

        button {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }

  &__content {
    @include mixins.text;

    & > div {
      p {
        margin: 24px 0 8px;
      }

      ul {
        margin: 0;
        padding-left: 24px;
      }
    }
  }
}
