@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.ncs-payment {
  height: 87vh;
  .step__head-title {
    @include typography.header3;
    color: colors.$upp-dark-content-primary;
    margin: 8px 0;
  }

  .stepper__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
