@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/dimensions";

@use "../common.scss";

.mp-failed-screen {
  @include common.container;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media (min-width: dimensions.$upp-tablet-min-width) {
    flex: none;

    .no-content-view {
      padding: 0;
    }
  }

  .no-content-view {
    &_icon {
      margin-bottom: variables.$indentation-xlg;
    }

    &_title {
      margin-bottom: variables.$indentation-lg;
    }

    &_subtitle,
    &_contact-info {
      @include typography.bodyRegular;
    }
  }
}
