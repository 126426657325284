.patient-acknowledgment-dialog {
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .mdc-dialog__header .mdc-dialog__title {
    &::before {
      content: none;
    }

    padding: 16px 0 0 16px;
    text-align: left;
    height: initial;
  }
}
