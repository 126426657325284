@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/dimensions";
@use "@app/theme/variables" as upp_variables;
@use "../scheduleBloodDraw";

.schedule-blood-draw {
  text-align: left;

  p a {
    color: colors.$upp-blue;
    text-decoration: none;
  }
  p a:hover {
    color: colors.$upp-blue;
  }

  &__blue-header {
    position: relative;
    width: 100%;
    height: 130px;
    background-color: colors.$upp-blue;
  }

  &__content {
    position: relative;
    top: -80px;
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: variables.$shadow;
    border-radius: 8px;
    background-color: colors.$upp-white;
    padding: 36px 16px 16px 16px;

    @media (min-width: dimensions.$upp-tablet-max-width) {
      max-width: upp_variables.$upp-container-max-width;
    }

    &-inner {
      @include scheduleBloodDraw.inner-box;
    }

    &-title {
      @include typography.header3;
    }

    &-description {
      @include typography.bodyRegular;
      margin-top: variables.$indentation-xlg;
      text-align: left;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    margin-top: variables.$indentation-xlg;
    gap: variables.$indentation-lg;

    p {
      @include typography.body;
      text-align: center;
    }
  }

  &__form-container {
    margin: variables.$indentation-xlg 0;

    form {
      display: block;

      .mdc-notched-outline {
        width: calc(100% - 1px);
      }

      .form-content {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: variables.$indentation-lg;

        .date-field.mdc-form-field.mdc-text-field {
          width: 100%;
        }

        & .mdc-text-field__input {
          &::placeholder {
            text-transform: uppercase;
          }
        }

        &::after {
          padding: 0;
        }
      }
    }

    label {
      text-align: left;
    }

    p {
      @include typography.body;
      text-align: center;
    }
  }

  &__footer {
    @include typography.body3;
    color: colors.$upp-dark-content-secondary;
    text-align: center;

    p a {
      @include typography.body3;
      @media (max-width: dimensions.$upp-mobile-max-width) {
        text-decoration: underline;
      }
    }
  }

  &__dob-failed {
    .date-of-birth-verification-failed__wrapper {
      height: calc(100vh - 60px);
      box-sizing: border-box;
    }
  }
}
