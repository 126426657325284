@use "@app/theme/mixins";
@use "@app/theme/variables";

.oh-scheduling-dialog {
  &__mobile-call {
    width: 100%;

    &-button {
      width: 100%;
    }
  }

  &__container {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
