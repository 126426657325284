@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.test-information-page {
  @include mixins.mobile {
    position: fixed;
    width: 100%;
  }

  .blue-header {
    @include mixins.mobile {
      padding-bottom: 69px;
    }
  }
  &__content {
    margin: -45px 24px 24px;
    position: relative;

    @include mixins.mobile {
      margin: -69px 16px 16px;
      max-height: calc(100vh - 180px);
      overflow-y: scroll;
      position: sticky;
    }
  }
}
