@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/components/material/button/mixins" as theme-mixins;

@use "../common.scss";

.public-draw-welcome {
  @include common.container;

  &__content {
    @include common.inner-box;
    max-width: 440px;
  }

  &__divider {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    text-align: center;
    font-size: 12px;
    color: colors.$upp-dark-content-secondary;
    margin: variables.$indentation-lg 0;

    &::before,
    &::after {
      content: "";
      flex-grow: 1;
      height: 1px;
      background: variables.$upp-notice-border-color;
    }

    &::before {
      margin-right: 10px;
    }

    &::after {
      margin-left: 10px;
    }
  }
}

.mobile-draw-card {
  background-color: colors.$upp-light-blue;
  border-radius: 8px;
  padding: variables.$indentation-md;
  cursor: pointer;

  &-header {
    @include typography.header3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: variables.$upp-primary-blue;

    .svg {
      display: inline;
      position: relative;
      transform: rotate(90deg);
    }
  }

  &-content {
    @include typography.bodyRegular;
    margin-bottom: 0;
    color: variables.$upp-dark-content-color;
  }
}

.lab-search {
  width: 100%;

  .form {
    display: flex;
    margin: variables.$indentation-xlg 0;
    .zipSelectorForm {
      padding: 0;
      width: 100%;
    }
  }
  & > button {
    width: 100%;
  }
}

.public-draw-welcome-info {
  &-title {
    @include mixins.text-title;
    font-size: 32px;
  }

  &-details {
    @include typography.bodyRegular;
  }
}
