@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/dimensions" as mixins;

.acknowledgment {
  position: relative;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    background-color: colors.$upp-dark-content-quinary;
    left: -(variables.$indentation-lg);
    right: -(variables.$indentation-lg);
    top: -20px;
    bottom: -72px;
    z-index: -1;
  }

  @include mixins.tablet {
    background-color: colors.$upp-white;

    &::after {
      display: none;
    }
  }

  .step {
    background-color: transparent;

    @include mixins.tablet {
      background-color: transparent;
    }

    &__head-subtitle {
      @include typography.header3;
      color: colors.$upp-dark-content-primary;
      margin-top: 0;
    }
  }
}
