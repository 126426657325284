@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables" as natera-variables;

.reset-password__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;

  .reset-password-progress {
    margin: (-(natera-variables.$indentation-lg))
      (-(natera-variables.$indentation-lg)) 0;
  }

  .reset-error {
    margin: natera-variables.$indentation-sm 0 0;
    display: none;

    .svg {
      display: flex;
    }

    &.reset-error--visible {
      display: flex;
    }

    div {
      padding: natera-variables.$indentation-lg natera-variables.$indentation-lg
        0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .title__container {
    h1 {
      @include typography.header2;
    }

    p {
      @include typography.body;
      margin-bottom: 0;
    }
  }
}
