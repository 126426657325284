@use "@app/theme/variables";

.payors-type-ahead {
  .typeahead-input {
    width: 100%;
  }

  &__menu .scrollbar-container.ps {
    padding: 0;
    max-width: variables.$upp-inner-box-max-width;

    ul {
      padding: 0;
    }
  }
}
