@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/components/material/button/mixins" as theme-mixins;

.form.reset-password-form {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: variables.$indentation-lg;
  height: 100%;

  .form-content {
    display: flex;
    flex-direction: column;
    gap: variables.$indentation-sm;
    .form-field {
      padding-bottom: 0;
      .mdc-text-field {
        width: 100%;
      }
    }
  }
  .form-buttons {
    display: flex;
    flex-direction: column;
    gap: variables.$indentation-lg;

    button {
      width: 100%;
    }
  }
}
