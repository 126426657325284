@use "@app/theme/variables";

.modify_mp_page {
  padding: 16px;
  background-color: variables.$natera-white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .step {
    max-width: 400px;
  }
}
