@use "@natera/theme/upp/typography";

.setup-profile-review-info {
  .info-card {
    padding: 0;

    &-field {
      .display-field .info-card-field__value--text {
        @include typography.bodyRegular;
      }
    }
  }
}
