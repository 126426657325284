@use "@app/theme/variables";

.welcome-message {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__avatar {
    width: 100px;
    height: 100px;
    margin-bottom: 24px;
    border-radius: 50%;
  }

  &__natera-logo {
    width: 154px;
    margin-bottom: 24px;
  }

  &__title,
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: variables.$neva-main-text;
    text-align: center;
    margin: 0;
  }

  &__subtitle {
    margin-top: 4px;
  }
}
