@use "@app/theme/mixins";
@use "@app/theme/variables";

.update-mobile-number-dialog {
  .mdc-dialog {
    .mdc-dialog__container {
      width: 100%;
      .mdc-dialog__surface {
        width: 100%;
        max-width: calc(100vw - 32px);

        @media (min-width: variables.$upp-mobile-width) {
          max-width: 343px;
        }

        .mdc-dialog__header {
          text-align: center;
        }

        .mdc-dialog__content {
          .form {
            max-width: 100%;
            .form-content {
              gap: 21px;
              padding-bottom: 18px;

              &::after {
                content: none;
              }
              .form-field {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
