@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors" as upp-colors;

.no-longer-available-page {
  height: 90vh;
  width: 100%;
  background-color: upp-colors.$upp-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__container__icon {
    margin: auto;
    margin-bottom: 24px;
  }
  &__container__title {
    @include typography.header3;
    text-align: center;
  }
}
