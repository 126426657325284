@use "@natera/theme/upp/dimensions";
@use "../scheduleBloodDraw";

.guest-schedule-blood-draw {
  @include scheduleBloodDraw.container;

  @media (min-width: dimensions.$upp-tablet-max-width) {
    padding: 0;
  }

  &__content {
    max-width: 100%;

    .progress-stepper {
      padding: 0;
      &__section {
        @include scheduleBloodDraw.inner-box;
      }
    }
  }
}
