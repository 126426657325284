@use "@app/theme/_mixins";
@use "@app/theme/_variables";

.form.update-phone-form {
  width: 100%;
  max-width: 500px;

  .form-content {
    display: grid;
    grid-auto-flow: row;
    align-items: start;
    gap: 16px;
    width: 100%;
    padding: 0;
    overflow: inherit !important;

    .mdc-form-field.mdc-text-field {
      width: 100%;
    }

    .buttons-container {
      display: grid;
      width: 100%;
      grid-auto-flow: column;
      gap: 16px;

      button[type="submit"] {
        margin: 0;
      }

      @include mixins.mobile {
        grid-auto-flow: row;
        gap: 0;

        button {
          width: 100%;
        }

        button[type="submit"] {
          margin-top: 16px;
        }
      }
    }
  }
}
