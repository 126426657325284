@use "@natera/theme/upp/colors";

.rating {
  &__container {
    margin-top: 12px;
    white-space: nowrap;
  }

  &__list {
    margin: 0;
    padding: 10px 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    gap: 0 4px;
    border: 1px solid #e5e5e5;
    border-radius: 26px;

    &.score-type {
      border: none;
      flex-wrap: wrap;
      gap: 8px 4px;
      padding: 0;
    }
  }
}

.stars-rating,
.scores-rating {
  &__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    padding: 0;

    & svg {
      display: block;
    }

    &:disabled {
      cursor: auto;

      & svg > path {
        fill: #b3b3b3;
      }
    }
  }
}

.scores-rating {
  &__button {
    width: 40px;
    height: 44px;
    border: 1px solid colors.$upp-blue;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: colors.$upp-blue;
    background-color: colors.$upp-white;

    &:disabled {
      cursor: auto;
      color: #b3b3b3;
      background-color: colors.$upp-white;
      border: 1px solid colors.$upp-gray-light;
    }

    &.selected-item {
      color: colors.$upp-white;
      background-color: colors.$upp-blue;

      &:disabled {
        color: colors.$upp-dark-content-tertiary;
        background-color: colors.$upp-dark-content-quaternary;
      }
    }
  }
}
