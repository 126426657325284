@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.appointment-timeslots {
  padding: 16px 0;
  border-bottom: 1px solid variables.$upp-notice-border-color;
  h4 {
    color: variables.$natera-gray-darker2;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 4px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.001em;
    margin: 0;
  }
}
