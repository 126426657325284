@use "@app/theme/_variables.scss" as uppVariables;
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/dimensions";
@use "@app/theme/mixins";

@use "../common.scss";

.signed-order-form-screen {
  @include common.container;

  &__content {
    .action-buttons {
      @include common.actionButtons;
    }
  }
  &-title {
    @include mixins.text-title;

    &__container {
      display: flex;
      flex-direction: column;
      gap: variables.$indentation-lg;
    }
  }
}

.signed-order-form {
  &-list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .mdc-radio-field {
      align-items: start;
      .mdc-radio {
        padding-top: 0;
      }
    }
  }

  &-item {
    border-bottom: 0.5px solid colors.$upp-dark-content-quaternary;
    box-sizing: border-box;
    padding: variables.$indentation-lg 0;

    &:last-child {
      border-bottom: none;
      padding-bottom: variables.$indentation-xlg;
    }

    &-title {
      @include typography.bodyRegular;
      font-weight: 600;
      color: uppVariables.$black;
    }

    &-desc {
      @include typography.bodyRegular;

      white-space: normal;
      margin-top: variables.$indentation-xsm;
      color: rgba(25, 28, 29, 0.87);
    }
  }
}
