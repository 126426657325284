@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.quick-reply {
  &__list-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px 10px 0 0;
    padding: 0;

    &.hidden-reply {
      visibility: hidden;
    }

    &.without-reply {
      display: none;
    }

    &.animation-reply {
      animation: 0.7s fadeOut;
    }
  }

  &__list-item {
    list-style-type: none;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    background-color: colors.$upp-blue;
    border: 1px solid colors.$upp-blue;
    border-radius: 26px;
    padding: 10px;
    cursor: pointer;
    height: 44px;
    min-width: 100px;
    animation: 0.5s messageAnimation;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus-visible {
      background-color: variables.$neva-primary-focus-state;
      outline: none;
    }

    &:active {
      border: 1px solid variables.$neva-primary-click-state;
      background-color: variables.$neva-primary-click-state;
    }

    &.quick-reply__button-secondary {
      background-color: colors.$upp-white;
      border: 1px solid colors.$upp-blue;

      &:active {
        border: 1px solid 1px solid colors.$upp-blue;
        background-color: variables.$neva-secondary-click-state;
      }

      &:focus-visible {
        background-color: variables.$neva-secondary-focus-state;
        outline: 1px solid colors.$upp-blue;
      }

      & .quick-reply__button-title {
        color: colors.$upp-blue;
      }
    }

    &-title {
      color: colors.$upp-white;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
