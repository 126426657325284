.setup-profile-dialog__content {
  max-width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  button.setup-profile-dialog__continue-button {
    margin-top: 16px;
    width: 100%;
  }

  h2 {
    line-height: 24px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 16px 0;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500px;
    text-align: center;
  }

  i {
    margin: 28px auto 8px;
  }

  .notice-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.001em;
  }
}
