@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.pagination {
  display: flex;
  align-items: center;
  gap: 24px;

  button.pagination-btn {
    padding: 0;
    height: 24px;
    min-width: 24px;
    border-radius: 3px;

    div {
      border-radius: 3px;
    }

    i.svg {
      width: 24px;
      height: 24px;
      font-size: 24px;
      margin: 0;
    }

    svg {
      fill: variables.$dark-secondary;
      border-radius: 3px;
    }
  }

  button.pagination-btn:disabled svg {
    fill: variables.$upp-input-border-color;
  }

  button.pagination-btn:active div,
  button.pagination-btn:focus div,
  button.pagination-btn:hover div {
    background-color: variables.$pagination-hover;
  }

  &__next-btn {
    transform: rotate(180deg);
  }

  &__pages {
    display: flex;
    align-items: center;
    gap: 24px;

    button.pagination-btn {
      color: variables.$black;
    }

    &--current.pagination-btn:disabled {
      background-color: variables.$pagination-active;
    }
  }
}
