@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.cancel-appointment-fail-dialog {
  &__content {
    @include typography.body;
    text-align: center;
    padding-top: variables.$indentation-xlg;
  }
}
