@use "@app/theme/variables";

@keyframes cycleOne {
  0% {
    background: variables.$neva-typing-40;
  }
  33.333% {
    background: variables.$neva-typing-100;
  }
  66.6667% {
    background: variables.$neva-typing-40;
  }
  100% {
    background: variables.$neva-typing-40;
  }
}

@keyframes cycleTwo {
  0% {
    background: variables.$neva-typing-40;
  }
  33.333% {
    background: variables.$neva-typing-40;
  }
  66.6667% {
    background: variables.$neva-typing-100;
  }
  100% {
    background: variables.$neva-typing-40;
  }
}

@keyframes cycleThree {
  0% {
    background: variables.$neva-typing-40;
  }
  33.333% {
    background: variables.$neva-typing-40;
  }
  66.6667% {
    background: variables.$neva-typing-40;
  }
  100% {
    background: variables.$neva-typing-100;
  }
}

.typing {
  &__container {
    position: relative;
    margin-left: 22px;
  }

  &__dots {
    position: relative;
    height: 26px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$natera-white;
    border-radius: 14px 14px 14px 0;
    filter: drop-shadow(0px 1px 0px variables.$neva-text-bubble-shadow);
  }

  &__dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: variables.$neva-medium-gray;
    display: block;
    float: left;
    margin: 0 0 0 4px;
    animation-direction: normal;

    &.first {
      margin: 0;
      animation: cycleOne 1s ease-in-out infinite;
    }

    &.second {
      animation: cycleTwo 1s ease-in-out infinite;
    }

    &.third {
      animation: cycleThree 1s ease-in-out infinite;
    }
  }
}
