@use "@natera/theme/upp/variables";

.form.login-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  .form-content {
    .form-field {
      .mdc-text-field {
        width: 100%;
      }
    }
    button.mdc-button {
      margin-top: variables.$indentation-sm;
      width: 100%;
    }
  }
}
