@use "@natera/theme/upp/colors";

.widget-header {
  height: 52px;
  display: flex;
  flex: 1 0 auto;
  padding: 0 4px;
  background-color: colors.$upp-blue;
  border-radius: 10px 10px 0 0;
  z-index: 1;

  &-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__left-holder,
  &__fix-holder,
  &__right-holder {
    width: 48px;
    flex: 1 0 auto;
  }

  &__right-holder {
    position: absolute;
    width: 258px;
    right: 0;

    & > div {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__bot-info-holder {
    width: 100%;
  }

  &__text-container {
    text-align: center;
    color: colors.$upp-white;
  }

  &__title {
    font-weight: 700;
    line-height: 16px;
    font-size: 14px;
    margin: 0;
  }

  &__sub-title {
    font-weight: 500;
    line-height: 14px;
    font-size: 14px;
  }
}
