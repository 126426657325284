@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.condition-card {
  &__wrapper {
    background-color: colors.$upp-white;
    border-radius: 8px;
    border: 1px solid variables.$neva-light-gray;
    margin: 12px 0 0;
    padding: 0;
  }

  &__container {
    list-style-type: none;

    &:first-child .condition-card__title {
      padding-top: 10px;
    }

    & ul {
      margin: 0;
      padding: 0;
    }
  }

  &__title {
    margin: 0 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: variables.$neva-secondary-text;
  }

  &__condition-container {
    border-bottom: 1px solid variables.$neva-light-gray;
    list-style-type: none;

    &:last-child {
      border-bottom: none;
    }
  }

  &__condition-button {
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 12px;
    text-align: left;

    &:disabled .condition-status__condition-name {
      color: variables.$neva-medium-gray;
      cursor: auto;
    }

    &:focus-visible {
      outline: 2px solid colors.$upp-blue;
    }
  }
}

.condition-status {
  &__icon-container {
    height: 24px;
    margin-right: 12px;

    & svg {
      display: block;
    }
  }

  &__condition-name {
    color: variables.$neva-main-text;
    cursor: pointer;
  }
}
