@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";

.highlight-wrapper {
  &__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: colors.$upp-dark-content-tertiary;
    z-index: 9;
    &.hidden {
      display: none;
    }
  }

  &__tooltip {
    display: flex;
    justify-content: center;
    &.hidden {
      display: none;
    }
  }

  &__content {
    position: relative;
    background-color: colors.$upp-white;
    box-sizing: border-box;
    z-index: 10;
    &.highlight-wrapper__form {
      border-radius: 10px;
      width: calc(100% + 12px);
      left: -6px;
      padding: 0 6px;
      margin: 0 auto;
    }
  }

  &__button {
    border-radius: variables.$border-radius-md;
    & > button {
      width: 100%;
    }
  }
}

.custom-tooltip {
  @include typography.bodyRegular;
  position: absolute;
  max-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: variables.$shadow;
  border-radius: variables.$indentation-lg;
  z-index: 11;

  &__container {
    padding: variables.$indentation-lg;
    border-radius: variables.$border-radius-md;
    background-color: colors.$upp-white;
    z-index: 11;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    margin: variables.$indentation-lg;
    cursor: pointer;

    path {
      fill: black;
    }
  }

  &__title {
    @include typography.subtitle;
    font-weight: 700;
    margin-right: variables.$indentation-xlg;
  }

  &__description {
    margin-top: variables.$indentation-sm;
  }

  &__arrow {
    width: 32px;
    height: 32px;
    position: absolute;
    background-color: colors.$upp-white;
    transform: rotate(45deg);
    border-radius: variables.$border-radius-xsm;
    box-shadow: variables.$shadow;
    z-index: 10;

    &.bottom {
      top: -12px;
    }

    &.top {
      bottom: -12px;
    }
  }
}
