@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/variables";
@use "../scheduleBloodDraw";

.guest-kit-details {
  @include scheduleBloodDraw.container;

  @media (min-width: dimensions.$upp-tablet-max-width) {
    padding: 0;
  }

  &__content {
    @include scheduleBloodDraw.inner-box;
    padding: variables.$indentation-xlg 0;

    .progress-stepper {
      padding: 0;
    }
  }
}
