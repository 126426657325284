@use "@app/theme/mixins";
@use "@natera/theme/upp/_colors";

.appointment-details {
  background: colors.$upp-white;
  min-width: 310px;
  border-radius: 8px;
  margin: 0 auto;
  padding: 16px;
  @include mixins.mobile {
    box-sizing: border-box;
  }

  &__header {
    color: colors.$upp-navy-blue;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    div {
      padding-top: 6px;
    }

    .svg {
      display: inline;
      position: relative;
      top: 4px;
    }

    button {
      padding: 0 10px;
    }
  }

  &__content {
    max-width: 310px;
    padding: 16px 26px 0;
    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 4px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.001em;
      margin: 0;
    }

    &__item {
      padding: 16px 0;
      border-bottom: 1px solid colors.$upp-dark-content-quaternary;
      h4 {
        // color: colors.$upp-gray-darker2;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 4px;
      }
    }
  }

  &__reference {
    max-width: 310px;
    padding: 0 26px;
    p {
      font-size: 14px;
      color: colors.$upp-dark-content-secondary;
    }
  }

  &__actions {
    max-width: 351px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    & > button {
      width: 100%;
      margin-bottom: 16px;
    }
    margin-left: 15px;
    @include mixins.mobile {
      margin-left: 0;
    }
    @media (max-width: 1020px) {
      margin-left: 0;
    }
  }

  .test-card {
    @include mixins.tablet {
      margin-bottom: 0;
    }
  }
}
