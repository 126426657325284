@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";

.no-content-view {
  background-color: colors.$upp-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 68px 34px 0px;

  @media (max-width: dimensions.$upp-mobile-max-width) {
    justify-content: center;
    padding: 0px variables.$indentation-lg;
  }

  &_icon {
    margin-bottom: 68px;
  }

  &_title {
    @include typography.header3;
    text-align: center;
    margin-bottom: variables.$indentation-xlg;
  }

  &_subtitle,
  &_contact-info {
    @include typography.body;
    text-align: center;
    margin-bottom: variables.$indentation-xlg;
  }

  &_action {
    width: 100%;
    text-align: center;
    margin-bottom: variables.$indentation-xlg;

    .mdc-button {
      width: 343px;

      @media (max-width: dimensions.$upp-mobile-max-width) {
        width: 100%;
      }
    }
  }
}
