@use "@natera/theme/upp/typography";
@use "@app/theme/_mixins.scss" as mixins;

.date-of-birth-verification-failed__wrapper {
  background-color: white;
  padding-top: 123px;
  height: 100%;
}

.date-of-birth-verification-failed__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 16px 0 16px;

  button.date-of-birth-verification-failed__close-button {
    margin-top: 16px;
    width: 352px;
    @include mixins.mobile {
      width: 100%;
    }
  }

  h2 {
    font-weight: 500;
    margin: 16px 0;
  }

  p {
    @include typography.subtitle2;
    margin: 0;
    text-align: center;
  }

  i {
    margin: 28px auto 8px;
  }

  .date-of-birth-verification-failed-text {
    @include typography.subtitle2;
    letter-spacing: 0.001em;
  }

  .date-of-birth-verification-failed__bottom-text {
    text-align: center;
    margin-top: 16px;
    a {
      text-decoration: underline;
    }
  }
}
