@use "@app/theme/_variables.scss" as variables;
@use "@natera/theme/upp/components/material/button/mixins";
@use "@natera/theme/upp/colors";

.sign-in__container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  a {
    color: colors.$upp-navy-blue;
  }

  label {
    color: colors.$upp-gray !important;
  }

  .signin-notification {
    overflow-y: inherit;
    display: none;

    &.signin-notification--visible {
      display: flex;
    }

    div {
      padding: 16px 16px 16px 0;
      text-align: left;
      color: colors.$upp-gray;

      p {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: 0;
      }

      p:first-child {
        font-weight: 500;
        margin-bottom: 8px;
      }
    }
  }

  .title__container {
    & > span {
      font-size: 14px;
      line-height: 18px;
    }

    & > h1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin: 0;
    }
  }

  .conditions__container {
    margin-top: 16px;
    font-size: 12px;
    line-height: 18px;
    color: colors.$upp-gray;
  }

  .separator__container {
    display: flex;
    align-items: center;
    margin: 24px 0;

    & > hr {
      width: 100%;
      border-left: none;
      border-right: none;
      border-bottom: none;
      border-top-color: rgba(0, 0, 0, 0.12);
    }

    & > span {
      padding: 0 16px;
      text-transform: uppercase;
      color: colors.$upp-gray;
    }
  }

  .to-sign-up__container {
    display: flex;
    justify-content: center;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: colors.$upp-gray;
  }

  a {
    button {
      width: 100%;
    }
  }
  .acknowledgements__text {
    font-size: 12px;
  }

  .forgot-password__container {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    button {
      @include mixins.navy-blue-button;
    }

    a {
      width: 100%;
    }
  }

  a {
    font-weight: 400;
    text-decoration: underline;
  }

  .acknowledgement-dialog__content {
    font-size: 14px;
  }
}
