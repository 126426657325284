@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@app/theme/mixins";

@use "../common.scss";

.public-mp-communications-preferences-wrapper {
  margin: 0 auto;
  width: 100%;

  @media (min-width: dimensions.$upp-tablet-min-width) {
    max-width: 600px;
    margin-top: 56px;
    box-shadow: variables.$shadow;
    border-radius: 8px;
  }
}

.public-mp-communications-preferences-header {
  max-height: 142px;
  width: 100%;
  margin: 0 auto;
  background: colors.$upp-light-blue;
  padding: 34px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: dimensions.$upp-tablet-min-width) {
    border-radius: 8px 8px 0 0;
  }
}

.public-mp-communications-preferences {
  @include common.container;
  border-radius: 0;

  @media (min-width: dimensions.$upp-tablet-min-width) {
    margin-top: 0;
    padding-top: variables.$indentation-lg;
    box-shadow: none;
    border-radius: 0 0 8px 8px;
  }

  &__content {
    @include common.inner-box;

    .update-communication-form__description {
      @include typography.header2;
    }
  }
}
