@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/dimensions";

@use "../common.scss";

.no-match-screen {
  @include common.container;

  &__content {
    .action-buttons {
      @include common.actionButtons;
    }
  }
  &-title {
    @include mixins.text-title;

    &__container {
      display: flex;
      flex-direction: column;
      gap: variables.$indentation-lg;
    }
  }

  &-subtitle {
    @include mixins.text-title;
    margin-bottom: variables.$indentation-lg;

    &__container {
      display: flex;
      flex-direction: column;
      gap: variables.$indentation-lg;
    }
  }
}
