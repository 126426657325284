@use "@natera/theme/upp/variables";

.partner-info-form__container.form {
  display: block;

  .form-content {
    padding: 0;

    &::after {
      padding-bottom: 0;
    }

    .form-field {
      margin-bottom: variables.$indentation-lg;
      .mdc-text-field {
        width: 100%;
      }
    }

    .stepper__actions {
      margin-top: variables.$indentation-xlg;
      gap: variables.$indentation-lg;

      button {
        margin: 0;
      }
    }
  }
}
