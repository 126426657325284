@use "@natera/theme/upp/typography";

.combo-order-modal {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      .mdc-dialog__actions {
        gap: 8px;

        button {
          width: 100%;
          margin-bottom: 16px;
        }
      }
    }
  }

  p {
    @include typography.body;
    letter-spacing: 0.014px;
    text-align: center;
    margin: 8px 0 28px 0;
  }
}
