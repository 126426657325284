@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";

.information-card {
  background-color: colors.$upp-white;
  border-radius: 8px;

  &-header {
    padding: variables.$indentation-lg;
    display: flex;
    align-items: center;
    background-color: colors.$upp-light-blue;
    border-radius: 8px 8px 0 0;

    .svg {
      height: 24px;
      width: 24px;
      display: flex;
      margin-right: 8px;

      * {
        fill: variables.$upp-secondary-blue;
        fill-opacity: 1;
      }
    }
    div {
      margin-right: auto;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: variables.$upp-secondary-blue;
    }
  }
}
