@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";

.result {
  &-details-container {
    @include mixins.mobile {
      position: fixed;
      width: 100%;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    background-color: variables.$upp-background-color;
  }

  &-header {
    background-color: colors.$upp-blue;
    height: 132px;
    box-sizing: border-box;
    padding: variables.$upp-tablet-padding;
    position: sticky;
    top: 55px;
    z-index: 0;

    @include mixins.mobile {
      padding: variables.$upp-mobile-padding;
      height: 132px;
    }

    &-title {
      display: flex;
      flex-direction: row;
      color: colors.$upp-white;
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      gap: 10px;
    }

    button {
      padding: 0;
      height: initial;
    }
  }

  &-card::-webkit-scrollbar {
    display: none;
  }

  &-card {
    margin: -64px 16px auto 16px;
    z-index: 2;
    background-color: colors.$upp-white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 3px rgba(0, 0, 0, 0.03),
      0px 1px 7px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    position: sticky;
    overflow-y: scroll;
    top: 120px;
    max-height: calc(100vh - 290px);

    &-header {
      text-align: center;
      margin-bottom: variables.$indentation-xxlg;

      h2 {
        font-weight: 550;
        font-size: 20px;
        line-height: 24px;
        margin: 0;
      }

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        margin: 0;
        margin-top: 8px;
        text-align: center;
        letter-spacing: 0.001em;

        color: variables.$upp-dark-content-color;
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: variables.$indentation-lg;

      h3 {
        @include typography.subtitle;
        margin: 0;
        margin-bottom: variables.$indentation-md;
      }

      button {
        width: 210px;
        height: 44px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
          font-weight: 400;
        }
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        margin: 0;
        text-align: center;
        letter-spacing: 0.001em;

        color: variables.$upp-dark-content-color;

        margin-bottom: 4px;
      }

      .block__supplemental {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 8px;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: center;
        padding: 0px;
        gap: 16px;
        margin-top: 8px;

        .link .sub-card {
          box-shadow: none;
        }

        @include mixins.mobile {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }

      .button-container {
        display: flex;
        flex-direction: column;

        @include mixins.mobile {
          width: 100%;
        }
      }

      .btn-schedule-session,
      .btn-share-fetal-sex {
        margin-top: 16px;
        display: flex;
        flex-direction: row;

        @include mixins.mobile {
          width: 100%;
        }

        button {
          span {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;

            text-transform: capitalize;
          }
        }
      }
    }
  }
}
