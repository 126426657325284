@use "@material/button";

.widget__message-button {
  & i {
    width: auto !important;
    height: auto !important;
  }

  & svg {
    display: block;
  }

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
  }
}
