@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.insurance-estimation-information {
  @include typography.bodyRegular;
  font-weight: 300;

  &__list {
    display: flex;
    flex-direction: column;
    gap: variables.$indentation-lg;
    margin: 0;
    padding-left: variables.$indentation-xlg;

    .information-item {
      .information {
        display: flex;
        flex-direction: column;
        gap: variables.$indentation-sm;

        .title {
          @include typography.buttonMedium;
        }

        .note {
          @include typography.body;
        }
      }
    }
  }
}
