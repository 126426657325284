@use "@app/theme/_mixins.scss" as mixins;
@use "@app/theme/variables" as upp_variables;
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/_dimensions";

.setup-profile__container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: upp_variables.$upp-container-max-width;

  @include dimensions.tablet {
    margin: 0 auto;
    height: unset;
  }

  @include mixins.mobile {
    margin: 0;
    height: 100%;
  }

  .setup-profile__progress {
    width: 100%;

    @include mixins.tablet {
      width: 100%;
    }
  }

  .setup-profile__wrapper {
    height: 100%;
    background-color: colors.$upp-white;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    @include mixins.mobile {
      justify-content: start;
    }
  }

  .setup-profile__content {
    height: 100%;
    width: 100%;
    max-width: 343px;
    background-color: colors.$upp-white;
    box-sizing: border-box;
    padding: variables.$indentation-lg 0;

    @include mixins.mobile {
      margin: 0;
      max-width: none;
      width: 100%;
      padding: variables.$indentation-lg;
    }

    @include mixins.tablet {
      margin: 0;
      height: unset;
    }

    .notification__container {
      margin-top: 0;

      @include mixins.mobile {
        margin: 0 0 variables.$indentation-lg;
      }
    }

    .setup-profile__title {
      @include typography.header2;
      margin: 0;

      @include mixins.mobile {
        margin: variables.$indentation-xsm 0 variables.$indentation-xlg;
      }
    }

    .step-number {
      @include typography.caption;
      color: colors.$upp-dark-content-secondary;
      margin: 0;
    }

    .setup-profile-form__container,
    .setup-profile-review-info {
      display: flex;

      .form {
        flex-direction: inherit;
      }

      .form-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        overflow: inherit !important;

        @include mixins.mobile {
          margin: 0;
        }
        .mdc-form-field.mdc-text-field {
          width: 100%;
        }

        button {
          margin-top: 20px;
        }
      }
    }

    .communication-preferences {
      padding: 0;
    }
  }
}
