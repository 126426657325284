.stepper__item.payment_method {
  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  &.first_item {
    padding-top: 0 !important;
  }

  &.last_item {
    border-bottom: none !important;
  }
}
