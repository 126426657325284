@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.launcher_icon__button {
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin: 16px;
  cursor: pointer;
  filter: drop-shadow(0px 4px 8px variables.$neva-navy-blue-40);
  animation-duration: 0.4s;
  animation-name: appear;

  &:focus-visible {
    outline: 2px solid colors.$upp-blue;
  }
}

.launcher_icon {
  width: 100%;
}
