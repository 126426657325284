@use "@app/theme/mixins";
@use "@app/theme/variables";
@use "@natera/theme/upp/components/material/button/mixins" as theme-mixins;

.activation__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  margin: 0 auto;

  @include mixins.tablet {
    max-width: variables.$upp-tablet-container-max-width;
    margin-top: 42px;
  }

  @include mixins.mobile {
    margin-top: 0px;
  }

  button {
    @include mixins.tablet {
      margin: 0 16px;
    }
  }

  .activation-pending-notification__content {
    display: flex;
    flex-direction: column;

    & > .whitespace {
      min-height: 16px;
      width: 100%;
    }

    p {
      margin-top: 0;
      margin-bottom: 8px;
    }

    p:nth-child(2) {
      font-weight: 500;
    }

    a {
      text-decoration: underline;
    }
  }

  .title__container {
    span {
      font-size: 14px;
    }

    h1 {
      font-size: 24px;
      font-weight: 500;
      margin: 0;
      line-height: 32px;
      text-align: center;
    }
  }

  & > i {
    margin: 23px auto;
  }

  .actions__container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    button:first-of-type {
      @include theme-mixins.navy-blue-button;
    }

    .whitespace {
      height: 16px;
    }
  }

  .activation__text {
    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }

    p {
      margin: 8px 0;
      text-align: center;
      font-size: 14px;
      line-height: 18px;

      b {
        font-weight: 500;
      }
    }

    .activation__text__email {
      margin: 16px 0;
    }
  }
}
