@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;
@use "@natera/theme/upp/colors";

.pdf__container::-webkit-scrollbar {
  display: none;
}

.pdf__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 16px;
  z-index: 2;
  height: 100%;
  border-radius: 6px;

  background-color: colors.$upp-white;
  color: variables.$black;

  font-size: 20px;
  line-height: 24px;

  overflow-y: scroll;

  @include mixins.mobile {
    padding: 0;
  }

  .pdf__container__error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 16px;
    margin-top: 180px;
    gap: 16px;

    .image {
      display: flex;
      justify-content: center;
    }

    text-align: center;

    .main-text {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    .sub-text {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .pdf__container__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    position: unset;
    height: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: variables.$natera-white;
    color: variables.$black;
    margin-bottom: 5px;

    @include mixins.mobile {
      display: none;
      margin-bottom: 0;
    }

    .pdf__container__header__title {
      margin-left: auto;
      margin-right: 0;
    }

    .pdf__container__header__download {
      width: 178px;
      margin-right: 15px;
    }
  }

  .pdf__container__header__result-pdf {
    position: relative;
    max-width: 900px;
    margin: 16px auto 0;

    .react-pdf__Page__canvas {
      width: 100% !important;
    }
  }

  .pdf__container__link {
    position: absolute;
    z-index: 10;
    background-color: transparent;
    border-radius: 20px;
    display: block;
  }

  .pdf__container__footer {
    display: none;
    @include mixins.mobile {
      display: block;
      bottom: 65px;
    }

    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    position: fixed;
    bottom: 0;

    .pdf__container__footer__download {
      width: 100%;
    }
  }

  .scrollbar-container {
    width: 100%;
  }
}
