@use "@app/theme/variables";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.block-info-card.banner {
  margin-bottom: variables.$indentation-sm;
  padding-left: variables.$indentation-lg;

  .block-info-card__icon {
    background: transparent;
  }
}

.block-info-card .block-info-card__content {
  .renewalBanner {
    &__title b {
      @include typography.button;
    }

    &__link {
      display: block;
      margin-left: -12px;
      text-decoration: underline;
      color: colors.$upp-blue;
      font-weight: 600;
    }
  }
}
