@use "@natera/theme/upp/variables";

.form.sign-up-by-password-form {
  .form-content {
    display: flex;
    flex-direction: column;
    padding: 0 0 16px;
    overflow: inherit !important;
    .form-field {
      .mdc-text-field {
        width: 100%;
      }
    }
  }
}
