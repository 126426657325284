@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors" as upp-colors;
@use "@natera/theme/upp/variables" as natera-variables;

.oon-cost-information {
  background-color: upp-colors.$upp-white;
  border-radius: natera-variables.$border-radius-sm;

  &__subtitle {
    @include typography.bodyRegular;
    color: upp-colors.$upp-dark-content-primary;
    margin: natera-variables.$indentation-lg 0;
  }

  &__estimate {
    display: flex;
    padding: natera-variables.$indentation-lg;
    flex-direction: column;
    align-items: flex-start;
    gap: natera-variables.$indentation-lg;
    background: variables.$natera-light-green;
    border-radius: natera-variables.$border-radius-sm;
    margin-bottom: natera-variables.$indentation-lg;
  }

  &__estimate-section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
    }

    &__header {
      @include typography.subtitle;
      font-weight: 600;
    }

    &__cost {
      @include typography.header;
      font-weight: 500;
    }

    &__information {
      @include typography.bodyRegular;
    }
  }

  &__text {
    margin-top: natera-variables.$indentation-lg;
    margin-bottom: natera-variables.$indentation-xlg;

    &__info {
      @include typography.bodyRegular;
    }
  }

  &__divider {
    background: upp-colors.$upp-dark-content-quaternary;
    height: 1px;
    width: 100%;
    margin-top: natera-variables.$indentation-xlg;
    margin-bottom: natera-variables.$indentation-xlg;
  }

  .stepper__actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: natera-variables.$indentation-xlg;

    button {
      width: 100%;
    }

    .back-button {
      background-color: transparent;
      color: upp-colors.$upp-navy-blue;
      border: 1px solid upp-colors.$upp-navy-blue;

      &:hover {
        background-color: rgba(upp-colors.$upp-navy-blue, 0.05);
      }
    }
  }
}

.oon-cost-estimate-tooltip {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  color: upp-colors.$upp-white;
  background-color: upp-colors.$upp-navy-blue;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
  cursor: pointer;
}

.other-payment-section {
  &__title {
    @include typography.header3;
    margin-bottom: natera-variables.$indentation-lg;
  }
}
