@use "@natera/theme/upp/colors";

.public-main {
  margin: auto;
  max-width: 400px;
  height: 100%;
  width: 100%;
  flex: 1;
  padding: 16px !important;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .mobile-view__container {
    margin: 0;
  }
  &--welcome {
    background-color: colors.$upp-white;
    padding: 0;

    .progress-stepper {
      padding: 0;
      .progress-stepper__header {
        display: none;
      }
    }
  }

  &--full-width {
    max-width: 100%;
    padding: 0 !important;
  }
}
