@use "@app/theme/variables";
@use "@app/theme/mixins";

.test-information-card {
  padding: variables.$indentation-lg;

  &--title {
    h3 {
      margin: 0 0 8px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.001em;
      color: variables.$upp-dark-content-color;
    }
  }

  .info-row {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @include mixins.mobile {
      font-size: 14px;
      line-height: 18px;
    }

    &--title {
      padding-bottom: variables.$indentation-lg;
      color: variables.$upp-dark-content-color;
    }
  }
}
