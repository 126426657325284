@use "@app/theme/variables";

.ca-pns-supplemental-dialog {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0 3px 4px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      letter-spacing: 0.1px;
      text-align: center;
    }

    button {
      width: 100%;
      margin-top: 20px;
    }
  }
}
