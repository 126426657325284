@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/components/material/button/mixins" as upp-button-mixins;
@use "@natera/theme/upp/typography" as upp-typography;
@use "@natera/theme/upp/dimensions" as upp-dimensions;
@use "@natera/theme/upp/variables" as upp-variables;
@use "@natera/theme/upp/colors";

.upp-test-card {
  grid-column: 1;
  margin-bottom: 0;

  @include mixins.tablet {
    margin-bottom: variables.$upp-mobile-padding;
  }

  .upp-test-card-title {
    display: flex;
    flex-direction: row;
    color: colors.$upp-blue;
    text-decoration: none;

    &:hover,
    &:focus {
      color: colors.$upp-blue;
      background: transparent;
    }

    span {
      max-width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & > i.svg {
      width: 24px;
      height: 24px;
    }

    &__arrow-svg {
      margin-top: -3px;
    }

    &__arrow-svg > svg > path {
      fill: colors.$upp-blue;
    }
  }

  &__content {
    border-radius: 0 8px 8px 0;
    background-color: colors.$upp-neutral-cream;
    height: 100%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
  }

  &__sidebar {
    padding: 16px 0 16px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    box-sizing: border-box;
    width: 100%;

    & > div {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__status {
    height: 30px;
    padding: 0 24px 0 8px;
    position: relative;

    @include upp-dimensions.tablet {
      height: 38px;
    }

    &--visible {
      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 2px solid colors.$upp-navy-blue;
        top: calc((100% - 16px) / 2);
        right: 0;
        background-color: colors.$upp-navy-blue;
      }
    }

    &--next {
      &::after {
        background-color: colors.$upp-neutral-cream;
      }
    }
  }

  &__label {
    @include upp-typography.caption;
    text-align: right;
    color: colors.$upp-dark-content-secondary;
    &--received-date {
      @media (max-width: upp-dimensions.$upp-tablet-max-width) {
        margin-top: upp-variables.$indentation-xsm;
      }
    }
  }

  &__value {
    @include upp-typography.body;
    text-align: right;
    color: colors.$upp-navy-blue;
    white-space: nowrap;
    margin: 2px 0 4px;

    @include upp-dimensions.tablet {
      @include upp-typography.bodyRegular;
    }
  }

  &__btn {
    @include upp-button-mixins.candy-pink-button;
    @include upp-typography.button;
    display: flex;
    justify-content: flex-end;
    margin-right: 4px;
    z-index: 1;
    position: relative;
  }

  &__timeline {
    height: 100%;
    background-color: colors.$upp-navy-blue;
    float: right;
    width: 8px;
    border-radius: 0 8px 8px 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .test-card {
    width: 100%;
    .test-card__stretch-container {
      max-width: none;
      .test-card__description {
        height: auto;
      }
    }
  }
}
