@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";

.info-card-field {
  .display-field {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px 0 0;
    gap: 8px;
    .info-card-field__value--text {
      @include typography.subtitle2;
      word-break: break-all;

      @media (min-width: variables.$upp-tablet-width) {
        @include typography.subtitle;
      }
    }
  }
}
