@use "@natera/theme/upp/colors";

.address-item-desc {
  white-space: normal;
  color: colors.$upp-dark-content-primary;
  padding: 4px 0 0 8px;
}

.patient-addresses__sub-actions > button {
  margin-top: 49px;
}

.patient-addresses__actions {
  display: flex;
  flex-direction: column;

  & > button {
    margin-bottom: 16px;
  }
}
