@use "@natera/theme/upp/colors";

.patient-addresses {
  &__item {
    width: 100%;
    border-bottom: 0.5px solid colors.$upp-dark-content-quaternary;
    box-sizing: border-box;
    padding: 15px 0;

    &:first-child {
      padding-top: 0;
    }

    .address-item-desc {
      color: colors.$upp-dark-content-primary;
      padding: 4px 0 0 8px;
    }
  }
}
