@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.supplemental-video-page {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  margin: 0;

  align-items: stretch;
  height: 100%;
  z-index: 3;

  .blue-header-content {
    position: static;

    @include mixins.mobile {
      position: absolute;
    }

    .blue-header_cross-content__title {
      margin: 0;
      padding: 24px 24px 16px;

      @include mixins.mobile {
        padding: 35px 16px;
        text-align: center;
      }
    }

    &__children {
      width: auto;
    }
  }

  &__info {
    margin: 0 auto;
    width: 85%;
  }

  &__info-title {
    font-weight: 500;
    color: variables.$upp-dark-blue;
  }

  &__info-text {
    color: variables.$upp-description-text;
    font-size: 14px;
  }
}
