@use "@app/theme/_variables.scss" as variables;

.flow-header__container {
  display: flex;
  flex-direction: column;
  padding: 16px;

  &--title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    padding: 24px;
    text-align: center;
    color: variables.$black;
  }
}
