@use "@natera/theme/upp/typography";
@use "@app/theme/variables";
@use "@app/theme/mixins";

.update-communication-form {
  .form-buttons {
    margin-top: 24px;
    border-top: none !important;
    padding: 0 !important;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 16px;
    & > button {
      width: 100%;
      margin-left: 0px !important;
    }

    @include mixins.mobile {
      flex-direction: column-reverse;
    }
  }

  &__description {
    @include mixins.text-bold;
  }

  &__content {
    .communication-content__item {
      display: grid;
      align-items: center;
      grid-gap: 16px;
      gap: 16px;

      &:not(:last-child) {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid variables.$upp-navigation-border;
      }

      h4 {
        @include mixins.text-bold;
        margin-bottom: 0;
      }
    }
  }

  .communication__item {
    &-title,
    &-content {
      white-space: normal;
    }

    &-title {
      @include mixins.text-bold;
      margin-top: 8px;
    }

    &-content {
      @include mixins.text;
    }
  }
}

.update-communication-dialog {
  &__header {
    display: flex;
    gap: 8px;
    margin-bottom: 24px;

    button {
      height: 24px;
      width: 24px;
      padding: 0;

      svg {
        fill: variables.$upp-dark-content-color;
      }
    }
  }

  h3 {
    @include typography.header3;
    text-align: center;
    margin: 0 0 16px;
  }

  &__buttons {
    display: grid;
    grid-auto-flow: row;
    align-items: center;
    gap: 16px;
    width: 100%;

    button {
      text-transform: capitalize;
    }
  }
}
