.sub-stepper {
  &.progress-stepper {
    padding: 0;
  }

  .progress-stepper__header {
    display: none;
  }
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.stepper__item {
  label {
    white-space: normal;
  }
}

.payment-method {
  &__form > .form-content {
    overflow: visible !important;
  }

  &__selector {
    margin-left: -10px;
  }
}
