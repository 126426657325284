@use "@natera/theme/upp/main.scss";
@use "./theme/variables" as variables;
@use "./theme/mixins";
@use "@natera/material/lib/textfield";

@use "@natera/navigation/link/link" with ($link-color: variables.$natera-system-link,
);

@use "@natera/material/lib/drawer/drawer.scss";

@use "@natera/platform/lib/components/main.scss" as platform-main;
@use "@natera/material/lib/button" as button;

@use "@natera/patient/lib/main.scss" as patient-main;

@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables" as natera-variables;

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: variables.$natera-white;
  width: 100%;
  height: 100%;
  position: relative;

  letter-spacing: variables.$upp-letter-spacing;

  --mdc-typography-body1-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-body2-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-button-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-caption-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-overline-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-subtitle1-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-subtitle2-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-headline1-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-headline2-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-headline3-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-headline4-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-headline5-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-headline6-letter-spacing: variables.$upp-letter-spacing;
  --mdc-typography-font-family: "Roboto Flex";

  @include mixins.mobile {
    min-height: fill-available;
  }
}

#root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 56px);
  background-color: variables.$upp-background-color;
  padding-top: 56px;

  @include mixins.mobile {
    min-height: fill-available;
  }
}

@keyframes mdc-dialog__surface_animation {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.notifications {
  z-index: 10;
}

a {
  color: variables.$upp-navy-blue-primary;
}

.mobile-view__container {
  max-width: 400px;
  margin: 0 auto;
}

.ws-pre-wrap {
  white-space: pre-wrap;
}

.progress-stepper {
  padding: 0 0 natera-variables.$indentation-lg;
  background-color: variables.$natera-white;

  &__header {
    margin: 0 0 16px;
    width: inherit;
    height: 8px;

    &__progress {
      display: none;

      &-steps {
        padding-top: 0;

        &.mdc-linear-progress {
          height: 8px;

          .mdc-linear-progress__bar-inner {
            border-top: 8px solid colors.$upp-blue;
          }
        }
      }
    }
  }

  &__section {
    max-width: 343px;
    margin: 0 auto;
  }
}

.form.info-card-form {
  width: 100%;
  height: 100%;

  .form-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    overflow: inherit !important;

    .mdc-form-field.mdc-text-field {
      width: 100%;
    }
    .mdc-form-field.mdc-select {
      width: 100%;
    }
  }
}

.full-height {
  height: 100%;
}

.link-button:focus {
  text-decoration: none;
  background-color: variables.$test-result-hover;
  outline: none;
}

.mdc-dialog__cross-button {
  @include mixins.focus-visible;
}

.stepper-page {
  &__container {
    display: flex;
    flex-direction: column;

    @include mixins.tablet {
      margin-right: 24px;
    }

    @include mixins.mobile {
      margin: 0;
    }

    .stepper {
      height: 100%;
      background-color: variables.$natera-white;
      padding: 0 16px 16px;

      > section {
        width: unset;
        max-width: 400px;
        margin: 0 auto;
      }

      &__container {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
      }
      &__head {
        &-title {
          @include mixins.text-title;
        }
        &-subtitle {
          @include mixins.text-bold;
          color: variables.$upp-dark-grey;
          margin-top: 16px;
          margin-bottom: 0;
        }
        &-text {
          @include mixins.text;
          color: variables.$upp-dark-grey;
          margin-top: 0;
        }
      }
      &__item {
        width: 100%;
        border-bottom: 0.5px solid variables.$natera-grey-border;
        box-sizing: border-box;
        padding: 15px 0;
        &-title {
          @include mixins.text-bold;
          color: variables.$upp-dark-grey;
        }
        &-desc {
          @include mixins.text;
          color: variables.$upp-dark-grey;
        }
      }
      &__form {
        .form-content {
          padding: 0;
          overflow: inherit !important;
        }
      }
      &__sub-actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 16px 0;
        & > button {
          width: 100%;
        }
      }
      &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 49px;
        & > button {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .lab-title {
      margin: 4px 0 16px;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
    }

    .stepper__head-text.stepper__head-text-bold {
      font-weight: 800;
    }
  }
}

.mdc-menu-surface.date-field-menu {
  & .rdp-base-wrapper {
    & .mdc-select__selected-text-container {
      padding-right: 0;
    }
  }
}

.mdc-drawer.drawer {
  background-color: variables.$color-navigaition;
  width: variables.$navigation-width;

  .mdc-drawer__content {
    .mdc-deprecated-list {
      .mdc-deprecated-list-item {
        color: rgba(255, 255, 255, 0.75);

        &--activated {
          color: #59cbe8;
        }
      }
    }
  }
}
