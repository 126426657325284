@use "@app/theme/_variables.scss" as variables;

.spinner-view {
  width: 100vw;
  height: 100vh;
  opacity: 0.75;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: variables.$natera-white;
}
