@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .loading {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .video-wrap {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    font-size: 20px;
    line-height: 24px;
    position: relative;

    &.video-wrap--loading {
      opacity: 0;
    }

    @include mixins.mobile {
      background-color: variables.$upp-background-color;
      flex-direction: column;
      margin: unset;
      width: 100%;
    }

    .wistia_responsive_padding {
      padding: 56.25% 0 0;
      position: relative;

      .wistia_responsive_wrapper {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        .wistia_embed {
          position: relative;
          height: 100%;
        }
      }
    }
  }
}
