@use "@app/theme/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/dimensions";

@mixin form-title-svg {
  width: 24px;
  height: 24px;
  fill: mdc-theme-prop-value(text-hint-on-light);
}

@mixin mobile {
  @media (max-width: #{dimensions.$upp-mobile-max-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{variables.$upp-tablet-width}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{variables.$upp-laptop-width}) {
    @content;
  }
}

@mixin from-laptop {
  @media (min-width: #{variables.$upp-laptop-width}) {
    @content;
  }
}

@mixin text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  white-space: normal;
  letter-spacing: 0.001em;
}

@mixin text-bold {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

@mixin text-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}

@mixin focus-visible {
  &:focus-visible {
    outline: 2px solid colors.$upp-blue;
    border-radius: variables.$natera-border-radius;
  }
}
