@use "@app/theme/variables";
@use "@natera/theme/upp/typography";
.enter-other-commercial-form {
  .selected-other {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 12px 16px;
    background-color: variables.$upp-blue-selected;
    color: variables.$upp-navy-blue-primary;
    @include typography.body;
    margin-bottom: 16px;
    cursor: default;
  }
}
