@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as uppVariables;
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.share-fetal-sex {
  &_container {
    @include mixins.mobile {
      position: fixed;
      width: 100%;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    background-color: uppVariables.$upp-background-color;
  }

  &_header {
    background-color: colors.$upp-blue;
    height: 132px;
    box-sizing: border-box;
    padding: variables.$indentation-xlg;
    position: sticky;
    top: 55px;
    z-index: 0;

    @include mixins.mobile {
      padding: variables.$indentation-lg;
    }

    &-title {
      @include typography.header3;
      display: flex;
      flex-direction: row;
      color: colors.$upp-white;
      gap: 10px;
    }

    button {
      padding: 0;
      height: initial;
    }
  }

  &_card::-webkit-scrollbar {
    display: none;
  }

  &_card {
    margin: -64px variables.$indentation-lg auto;
    z-index: 2;
    background-color: colors.$upp-white;
    box-shadow: variables.$shadow-1dp;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: variables.$indentation-lg;
    padding: variables.$indentation-lg;
    position: sticky;
    overflow-y: scroll;
    top: 120px;
    max-height: calc(100vh - 220px);

    &-header {
      text-align: center;

      h2 {
        @include typography.header3;
        margin: 0;
      }

      h3 {
        @include typography.body;
        margin: variables.$indentation-lg 0 0;

        @include mixins.mobile {
          text-align: left;
        }
      }
    }

    &-main {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: variables.$indentation-lg;
      margin-top: variables.$indentation-lg;

      .password {
        @include typography.body;
        display: flex;
        align-items: center;
        gap: variables.$indentation-sm;

        &_content {
          font-weight: 700;
        }

        .copy-password-btn {
          width: auto;
        }
      }

      .information-container {
        display: flex;
        flex-direction: column;
        gap: variables.$indentation-lg;

        .note {
          margin: 0;

          .notification__content {
            @include typography.subtitle2;
          }
        }

        .disclaimer {
          @include typography.body3;
          color: colors.$upp-dark-content-secondary;

          min-width: fit-content;
          width: min-content;
        }
      }

      button {
        @include mixins.mobile {
          width: 100%;
        }
      }
    }
  }
}
