.acknowledgments-dialog {
  & > .acknowledgement-dialog__content {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }

  .acknowledgement-dialog__content__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .acknowledgement-dialog__content__cookies {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding-left: 40px;
    line-height: 17px;
  }
}
