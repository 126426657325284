@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.billing-updates-card-container {
  padding: variables.$indentation-lg;
  background-color: colors.$upp-white;
  width: 100%;
  box-sizing: border-box;

  a.link {
    text-decoration: none;
  }
}

.billing-updates-card {
  display: flex;
  padding: 16px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  background-color: colors.$upp-white;
  border: 1px solid colors.$upp-light-blue;
  border-radius: variables.$border-radius-sm;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);

  &__icon,
  &__arrow {
    flex-shrink: 0;
  }

  &__icon {
    width: 36px;
    height: 36px;
  }

  &__arrow {
    width: 24px;
    height: 24px;
  }

  &__text {
    @include typography.subtitle;
    flex-grow: 1;
    color: colors.$upp-navy-blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
