@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables" as uppvariables;

.understanding-your-results {
  display: flex;
  flex-direction: column;

  &__header {
    background-color: colors.$upp-blue;
    color: colors.$upp-white;

    height: 108px;

    position: sticky;
    top: 55px;

    .mdc-icon-button svg {
      fill: colors.$upp-white;
    }

    @include mixins.mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 94px;
      box-sizing: border-box;
      z-index: 6;

      h2 {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        margin: auto auto;
      }

      button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 16px;
      }
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;

    font-size: 20px;
    line-height: 24px;

    .material-icons {
      height: 24px;
      width: 24px;
      padding: 0;

      svg {
        fill: colors.$upp-white;
      }
    }
  }

  &__container::-webkit-scrollbar {
    display: none;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    margin: -44px 24px auto 24px;
    z-index: 2;

    background-color: colors.$upp-white;
    color: variables.$black;

    font-size: 20px;
    line-height: 24px;

    position: sticky;
    top: 220px;
    overflow-y: scroll;
    max-height: calc(100vh - 260px);

    @include mixins.mobile {
      background-color: variables.$upp-background-color;
      margin: unset;
      position: absolute;
      z-index: 3;
      top: 94px;
      left: 0;
      right: 0;
      width: 100%;
      min-height: 100%;
      padding: 0;

      .ps {
        overflow: visible !important;
      }
    }

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: unset;
      height: auto;
      width: 100%;
      box-sizing: border-box;

      background-color: colors.$upp-white;
      color: variables.$black;

      @media (max-width: 1056px) {
        flex-direction: column;
      }

      @include mixins.mobile {
        display: none;
      }

      &__title {
        margin: 0;
      }

      button {
        width: 191px;
        margin-left: 16px;
        @media (max-width: 758px) {
          width: 100%;
        }
      }
    }

    &__footer {
      display: none;
      box-sizing: border-box;
      width: 100%;
      padding: 16px;

      @include mixins.mobile {
        display: block;
      }
    }

    &__action-button {
      height: auto !important;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    &__buttons-wrapper {
      display: flex;
    }

    .scrollbar-container {
      padding: 0 15px;
      @include mixins.mobile {
        padding: 0;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 20px;
    }
  }

  &__content {
    background: colors.$upp-white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.03),
      0 1px 7px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 15px 16px 30px;
    margin: 16px 18px;

    h3 {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin: auto;
      @include mixins.mobile {
        width: initial;
        padding: 0 40px;
      }
      @media (min-width: 421px) {
        display: none;
      }
    }

    p {
      color: colors.$upp-dark-content-secondary;
      @include typography.body;
      letter-spacing: 0.001em;
      margin-bottom: 32px;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 160px;
    }

    svg {
      display: block;
      margin: 0 auto;
      height: 220px;
      width: 220px;
    }

    b {
      font-weight: 500;
    }

    &__card-wrapper {
      :last-child {
        margin-bottom: 61px;
      }

      &__card {
        background: colors.$upp-neutral-cream;
        color: colors.$upp-dark-content-secondary;
        border-radius: 8px;
        padding: 18px 28px 15px;
        margin-top: 16px;
        @include typography.body;
      }
    }

    &__wrapper {
      margin-top: -45px;
    }

    &-oncology {
      padding: uppvariables.$indentation-lg;
      @include typography.bodyRegular;

      svg {
        display: block;
        margin: 0 auto;
        height: 114px;
      }

      .br {
        content: "";
        display: block;
        margin-top: uppvariables.$indentation-md;
      }

      h2 {
        text-align: center;
        @include typography.header2;
      }

      &-card-description {
        padding: uppvariables.$indentation-lg;
        background-color: variables.$natera-green-light;
        border-radius: uppvariables.$indentation-xsm;
        margin-bottom: uppvariables.$indentation-md;

        div {
          padding: uppvariables.$indentation-xsm 0;
          @include typography.bodyRegular;

          b {
            @include typography.subtitle;
          }
        }
      }
    }
  }
}
