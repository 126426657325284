@use "@natera/theme/upp/components/material/button/mixins";

.authentication-providers__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;

  button {
    @include mixins.navy-blue-button;

    span {
      display: flex;
      align-items: center;
    }
  }

  .autentication-providers__icon {
    margin-right: 8px;
  }

  .autentication-providers__icon-google {
    height: 16px;
  }

  & > .whitespace {
    height: 16px;
  }
}
