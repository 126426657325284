@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";

.insurance-cost-estimation-form {
  hr {
    border-top: 1px solid colors.$upp-dark-content-quaternary;
    margin-top: variables.$indentation-lg;
    margin-bottom: variables.$indentation-lg;
  }

  .financial-aid-card {
    margin-top: variables.$indentation-lg;
  }
}
