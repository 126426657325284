@use "@app/theme/variables";
@use "@app/theme/mixins";

.common-form-actions-view {
  display: grid;
  grid-auto-flow: row;

  align-items: center;
  gap: 16px;
  width: 100%;

  @media (min-width: variables.$upp-mobile-width) {
    padding-top: 8px;
    grid-auto-flow: column;
  }

  button {
    width: 100%;
    @include mixins.mobile {
      min-height: 44px;
    }

    &[type="submit"]:disabled {
      color: variables.$natera-white;
      background-color: variables.$upp-light-blue;
    }
  }
}
