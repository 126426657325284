@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px variables.$neva-scrollbar-overlay-shadow;
  background-color: variables.$neva-secondary-text;
}

@keyframes messageAnimation {
  from {
    opacity: 0;
    transform: scale(0.7);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s linear;
  }
}

@mixin core-container-styles {
  width: 100%;
  height: 100%;
}

.widget-container {
  position: fixed;
  width: 414px;
  height: 80%;
  max-height: 1100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  box-shadow: 2px 2px 24px variables.$neva-widget-shadow;
  background-color: variables.$upp-background-color;
  border-radius: 10px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mixins.mobile {
    @include core-container-styles;
  }

  & .linkEdited,
  .linkCreated {
    cursor: pointer;
    color: colors.$upp-blue !important;
    text-decoration: underline;

    & svg {
      width: 22px;
      height: 22px;
    }
  }

  & .linkCreated {
    white-space: nowrap;
    display: inline-flex;
  }
}
