@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors" as upp-colors;

.ncs-cost-information {
  .step__head-text {
    @include typography.bodyRegular;
    color: upp-colors.$upp-dark-content-primary;
    margin: 16px 0;
  }

  &__estimate {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: variables.$natera-light-green;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  &__estimate-section {
    display: flex;
    flex-direction: column;
    gap: 0;

    &__title {
      display: flex;
      flex-direction: row;
      gap: 6px;
    }

    &__header {
      @include typography.subtitle;
      font-weight: 600;
    }

    &__cost {
      @include typography.header;
      font-weight: 500;
    }

    &__information {
      @include typography.bodyRegular;
    }

    &__information-bold {
      font-weight: 700;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__title {
      @include typography.header3;
    }

    &__info {
      @include typography.bodyRegular;
    }
  }

  .stepper__actions {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 32px;
  }
}

.ncs-cost-estimate-tooltip {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 50%;
  color: upp-colors.$upp-white;
  background-color: upp-colors.$upp-navy-blue;
  text-align: center;
  margin-top: 2px;

  &:hover {
    cursor: pointer;
  }
}

.other-payment-section {
  &__title {
    @include typography.header3;
  }
}
