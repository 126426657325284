@use "@app/theme/variables";

.message-body {
  &__container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 0 10px 16px;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    &.message-body__without-logo {
      margin-left: 56px;
    }

    &.send-by-user {
      justify-content: flex-end;
      margin-right: 16px;

      & .text-message__bubble {
        background-color: variables.$neva-sent-message;
        box-shadow: none;
        border-radius: 14px 14px 0 14px;
        margin-right: 0;
      }
    }

    &.message-body__welcome-message {
      margin: 0 0 32px 0;

      &.first-message {
        margin-top: 20px;
      }
    }
  }
}
