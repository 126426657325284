@use "@app/theme/variables";
@use "@app/theme/mixins";

.unable_verify_account_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  background-color: variables.$natera-white;
  margin: -24px;
  justify-content: center;

  @include mixins.mobile {
    margin: -16px;
  }

  &-icon {
    margin-bottom: 30px;
  }

  &-title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin: 0px 16px 16px;
    line-height: 32px;
  }

  &-subtitle {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    margin: 0px 16px 16px;
  }

  &-button {
    margin: 0px 16px 16px;
    width: 100%;
    max-width: 343px;
  }
}
