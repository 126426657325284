@use "@app/theme/_variables.scss" as variables;

.resend-link__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;

  .resend-link-progress {
    margin: -16px 0 0 0;
  }

  .resend-error {
    margin: 8px 0 0;
    display: none;

    .svg {
      display: flex;
    }

    &.resend-error--visible {
      display: flex;
    }

    div {
      padding: 16px 16px 16px 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .form__container {
    display: flex;

    .form {
      flex-direction: inherit;
    }

    .form-content {
      padding: 0;
      overflow: inherit !important;
      gap: variables.$indentation-lg;

      a {
        button {
          width: 100%;
        }
      }
    }
  }
}
