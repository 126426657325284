@use "@app/theme/variables";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables" as upp-variables;

.payment-form {
  &__container {
    .notification__container {
      margin: 16px 0;
    }
  }

  &__body {
    margin-top: 16px;
  }
}

.payment-details-container {
  background: colors.$upp-white;
  border-top: 1.5px solid upp-variables.$shadow;
  box-shadow: 0px 3px 12px -2px upp-variables.$shadow;
  margin-top: 4px;
  margin-left: -16px;
  margin-right: -16px;
  .expansion-panel {
    .mdc-deprecated-list-item {
      &__meta {
        .svg > svg {
          fill: variables.$prebill-payment-information-text-color;
        }
      }
      .mdc-deprecated-list-item__text {
        width: 100%;
        .label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > div {
            @include typography.subtitle;
            color: variables.$prebill-payment-information-text-color;
          }
        }
      }
    }
    &__content {
      transition: 0.25s ease;

      .description__columns {
        display: flex;
        flex-direction: row;
      }

      .description {
        &__block {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
        }

        &__header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @include typography.subtitle;
          color: variables.$gray-darker;
          border-top: 1px solid upp-variables.$shadow;
          padding-top: 16px;
        }

        &__detail {
          @include typography.bodyRegular;
          margin-top: 9px;
          color: colors.$upp-dark-content-primary;
        }
      }

      .description__amonut {
        display: flex;
        flex-direction: row;
        align-self: center;
        font-weight: 700;
        margin-left: auto;
      }
    }
    &__content-expanded {
      padding: 0 16px 16px 16px;
    }
  }
}
