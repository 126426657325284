@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.image-message {
  &__wrapper {
    border: none;
    border-radius: 14px;
    padding: 0;

    &:focus-visible {
      outline: 2px solid colors.$upp-blue;
    }
  }

  &__template-image {
    max-width: 290px;
    width: 100%;
    cursor: zoom-in;
    filter: drop-shadow(0px 1px 0px variables.$neva-text-bubble-shadow);
    border-radius: inherit;
    display: block;

    @media (max-width: variables.$upp-mobile-width) {
      max-width: 282px;
    }
  }
}
