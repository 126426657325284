@use "@app/theme/mixins";
@use "@app/theme/variables";

.update-info-notification__container {
  margin: 16px 0;

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}

.personal-info-form__container.form {
  display: block;
  margin-top: 8px;

  .form-content {
    padding: 0;

    &::after {
      padding-bottom: 0px;
    }
  }

  .display-field {
    font-weight: 400;
    font-size: 14px;
    color: variables.$black;
    padding: 16px 0 20px 0;
  }
}

.personal-info-modal {
  box-sizing: border-box;

  &__container {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.1px;
      text-align: left;
    }

    p:last-of-type {
      margin-bottom: 24px;
    }

    button {
      width: 100%;
    }

    button:last-of-type {
      margin-top: 16px;
    }
  }
}
