@use "@app/theme/_mixins";
@use "@app/theme/_variables";

.stepper__form {
  .form {
    .form-content {
      padding: 0;
      overflow: inherit !important;

      .stepper__actions {
        margin-top: 16px;
      }
    }
  }
}
