@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors" as upp-colors;

.ptpa-page {
  background-color: upp-colors.$upp-white;

  .ptpa-estimate {
    background-color: upp-colors.$upp-white;
    border-radius: 8px;
    height: 84vh;
    max-width: 600px;
    margin: 0 auto;

    &__info-section {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      background: variables.$natera-light-green;
      border-radius: 8px;
      margin-bottom: 16px;
    }

    &__info-item {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__info-label {
      @include typography.subtitle;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    &__info-value {
      @include typography.bodyRegular;
    }

    &__info-tooltip {
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      border-radius: 50%;
      color: upp-colors.$upp-white;
      background-color: upp-colors.$upp-navy-blue;
      text-align: center;
      line-height: 20px;
      font-size: 14px;
      cursor: pointer;
    }

    &__whats-next {
      gap: 4px;
      &-title {
        @include typography.header3;
      }

      &-content {
        @include typography.bodyRegular;
      }
    }
  }
}
