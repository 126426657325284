.send-activation-email-dialog {
  .mdc-dialog {
    .mdc-dialog__header {
      gap: 8px;
    }

    .mdc-dialog__content {
      .send-activation-email-dialog__content {
        margin-top: 16px;
        font-size: 14px;
        line-height: 18px;
      }

      .mdc-dialog__actions {
        margin-top: 16px;
      }
    }
  }
}
