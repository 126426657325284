@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/typography";

@use "../common.scss";

.kit-req-form-confirmation {
  @include common.container;

  &__content {
    @include common.inner-box;
    max-width: 440px;

    &-description {
      @include typography.bodyRegular;
      margin-top: variables.$indentation-sm;
      text-align: left;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
