@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.insurance-estimation-data {
  display: flex;
  flex-direction: column;
  gap: variables.$indentation-sm;

  .content {
    display: block;
    padding-top: variables.$indentation-md;
    padding-bottom: variables.$indentation-md;
    @include typography.body;
  }
}
