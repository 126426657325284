@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@app/theme/variables";

.survey-section {
  border: 1px solid variables.$prebill-survey-info-box-bg-color;
  border-radius: 8px;
  background-color: variables.$prebill-survey-info-box-bg-color;
  padding: 16px;
  margin-bottom: 32px;
  margin-top: 24px;
  &__title {
    @include typography.subtitle;
    color: colors.$upp-dark-content-primary;
    text-align: center;
  }
  &__description {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-primary;
    text-align: center;
    margin-top: 4px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 16px;

    &__yes {
      margin-right: 8px;
    }

    &__no {
      margin-left: 8px;
    }
  }
}
