@use "@app/theme/variables";
@use "@app/theme/mixins";

.edit-mfa {
  width: 100%;
  margin: 0;
  position: relative;

  &__title::-webkit-scrollbar {
    display: none;
  }

  &__title {
    padding: 16px;
    background-color: variables.$natera-white;
    border-radius: 6px;
    margin: 0 variables.$upp-tablet-padding 8px variables.$upp-tablet-padding;
    overflow-y: scroll;
    position: sticky;
    z-index: 1;
    max-height: calc(100vh - 180px);

    &:nth-child(2) {
      margin-top: -45px;
    }

    @include mixins.tablet {
      margin: 0 0 8px 0;

      &:nth-child(2) {
        margin-top: -10px;
      }
    }
  }
}
