@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.base-modal-dialog {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      margin: 16px 0 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }
  &__element {
    width: 100%;
  }

  &__content {
    margin-top: 24px;
    p {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: variables.$upp-letter-spacing;
      color: variables.$black;
    }
  }
  &__actions {
    margin-top: 24px;

    button {
      width: 100%;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}
