@use "@app/theme/mixins";
@use "@app/theme/variables.scss";

.test-history {
  &__section {
    padding: 16px;
    background-color: variables.$natera-white;
    border-radius: 6px;
  }
  &__container {
    width: 50%;

    @include mixins.tablet {
      width: 100%;
    }
  }
  &__filters {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 16px;

    button {
      div {
        display: none;
      }
    }
  }

  &__pagination {
    display: flex;
    margin-top: 28px;
    justify-content: center;
  }

  .blue-header-content {
    @include mixins.tablet {
      position: fixed;
    }
  }
}
