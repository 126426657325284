@use "@natera/material/lib/button";
@use "@app/theme/variables";
@use "@natera/theme/upp/colors";
@use "@app/theme/mixins";
@use "@natera/theme/upp/dimensions";

.navigation {
  width: 200px;
  box-sizing: border-box;
  background-color: transparent;
  top: 0;
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: -webkit-fill-available;
  padding-top: 80px;
  padding-bottom: 20px;

  @include mixins.mobile {
    z-index: 6;
    width: 270px;
    height: 100%;
    max-width: 270px;
    background: variables.$upp-background-color;
    left: 0;
    margin: 0;
    padding: 24px;
    overflow: hidden;
    transition: max-width 175ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      box-shadow 175ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  @media (min-width: dimensions.$upp-tablet-min-width) and (max-width: variables.$upp-laptop-width) {
    margin-left: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0;
    margin: 0;

    .nav-item {
      margin-bottom: 24px;
    }

    &.navigation__bottom-routes {
      margin-top: auto;
      order: 3;

      .nav-item {
        margin-bottom: 0;
      }

      .logout-btn {
        display: flex;
        justify-content: start;
        height: 24px;
        width: 100%;
        padding: 0;

        @include button.ink-color(colors.$upp-gray);

        @include mixins.focus-visible;

        .mdc-button__ripple {
          display: none;
        }

        .material-icons {
          height: 21px;
          width: 24px;
          margin-right: 24px;
        }

        div {
          display: none;
        }

        span {
          letter-spacing: -0.24px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }

    .billing-center {
      display: none;
    }
  }

  &--closed {
    @include mixins.mobile {
      padding: 0;
      max-width: 0;
    }
  }

  &__main {
    &--desktop {
      @include mixins.mobile {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @include mixins.mobile {
        display: block;
      }
    }
  }

  &__divider {
    background: variables.$upp-notice-border-color;
    height: 1px;
    width: 100%;
    margin-bottom: 24px;

    display: none;
    @include mixins.mobile {
      display: block;
    }
  }

  &__logo {
    margin: 14px 0;

    display: none;
    @include mixins.mobile {
      display: block;
    }
  }
}

.navigation__overlay {
  background: variables.$upp-dark-content-color;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: none;
  @include mixins.mobile {
    display: block;
  }
  &--closed {
    @include mixins.mobile {
      display: none;
    }
  }
}
