@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.passwordField {
  .mdc-text-field.mdc-text-field--with-trailing-icon,
  .mdc-text-field__icon--trailing {
    cursor: pointer;
    pointer-events: all;
  }

  &-icon:focus-visible {
    outline: none;
  }

  &-icon:focus-visible::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -1px;
    width: 44px;
    height: 44px;
    border: 2px solid colors.$upp-blue;
    border-radius: variables.$natera-border-radius;
  }
}
