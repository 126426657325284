@use "@natera/theme/upp/colors";

.progress__container {
  height: 8px;
  background-color: colors.$upp-dark-content-quaternary;

  .progress {
    height: 100%;
    background-color: colors.$upp-blue;
  }
}
