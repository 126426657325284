@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@material/button";

.idle-dialog {
  button {
    margin-top: 16px;
    width: 100%;
  }
  &__logoutButton {
    button {
      padding: 0;
      margin: 0;
    }
  }
}
