@use "@app/theme/variables";
@use "@app/theme/_mixins";
@use "@natera/theme/upp/dimensions";

.header {
  height: fit-content;
  width: 100%;
  padding: 4px 24px;
  background-color: variables.$natera-white;
  border-bottom: 1px solid variables.$natera-grey-border;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  @include mixins.tablet {
    border-bottom: none;
    height: unset;
  }

  @include mixins.mobile {
    border-bottom: none;
    height: unset;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: variables.$upp-laptop-width;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .header__menu {
    order: 0;
    cursor: pointer;

    width: 48px;

    @media (min-width: dimensions.$upp-tablet-min-width) {
      order: 1;
      width: 69px;
    }

    &-content {
      @media (min-width: dimensions.$upp-tablet-min-width) {
        display: none;
      }
      .mdc-icon-button {
        padding-left: 0;
      }
    }

    &-burger-hidden {
      order: 0;
    }
  }

  .header__logo {
    order: 1;
    padding: 12px 0;

    @include mixins.mobile {
      margin: 0 auto;
    }

    @include mixins.tablet {
      margin: 0;
    }

    a {
      @include mixins.focus-visible;
      display: flex;
    }
  }

  .header__language {
    order: 2;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .svg {
    display: flex;
  }
}

.header_limited {
  max-width: variables.$upp-mobile-width;
  margin: auto;
  left: unset;

  @include mixins.mobile {
    height: unset;
  }
}
