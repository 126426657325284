@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.billing-updates {
  background-color: colors.$upp-white;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: center;
    padding: variables.$indentation-lg 0;
    background-color: colors.$upp-white;
    border-bottom: 1px solid colors.$upp-light-blue;
    z-index: 1;
    width: 100%;
  }

  &__back-icon {
    cursor: pointer;
    position: absolute;
    left: variables.$indentation-lg;
  }

  &__title {
    @include typography.header3;
    margin: 0;
  }

  &__notifications {
    flex-grow: 1;
    overflow-y: auto;
    padding: variables.$indentation-lg;
  }
}
