@use "@natera/theme/upp/colors";
@use "@natera/material/lib/select/mixins" as select-mixins;
@use "@material/select/select-theme" as select-theme;
@use "@app/theme/_variables.scss" as variables;

.date-of-birth-form-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
  justify-content: space-between;

  .mdc-select {
    @include select-mixins.activated-fill-color(colors.$upp-white);
    @include select-mixins.focused-fill-color(colors.$upp-white);
    @include select-theme.outline-shape-radius(8px);

    &__anchor {
      max-width: 167px;
      height: 44px !important;
      background-color: colors.$upp-white !important;
    }
  }

  .form-field {
    &--invalid {
      .mdc-form-field.mdc-select .mdc-select__anchor {
        @include select-mixins.outline-color(colors.$upp-error);
        @include select-mixins.ink-color(colors.$upp-error);
        @include select-mixins.container-fill-color(colors.$upp-transparent);
      }
    }
    .mdc-text-field-helper-line {
      &--invalid {
        .mdc-text-field-helper-text {
          color: colors.$upp-error;
        }
      }
    }
  }
}

.date-of-birth-form-button {
  margin-top: 16px;
}
.date-of-birth-form {
  .form__container {
    display: flex;

    .form {
      flex-direction: inherit;

      .form-content {
        padding: 0;
        overflow: visible !important;
        gap: variables.$indentation-lg;
      }

      .scrollbar-container {
        .ps__rail-y {
          opacity: 0;
        }
      }

      .form-buttons {
        margin-top: 16px;
        button {
          width: 100%;
        }
      }
    }
  }
}
