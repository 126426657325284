@use "@app/theme/variables";

.sub-card {
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 145px;
  min-height: 127px;
  padding-bottom: 8px;

  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  .title {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;

    text-align: center;

    word-wrap: break-word;
    white-space: pre-line;

    width: 124px;
    color: variables.$black;
  }
}
