@use "@natera/theme/upp/variables" as upp-variables;
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors" as upp-colors;

.informational-action-card {
  &__title {
    @include typography.header3;
  }

  &__box {
    display: flex;
    flex-direction: row;
    padding: 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    margin-bottom: 16px;
    cursor: pointer;
    border-radius: 8px;
    background: upp-colors.$upp-white;
    box-shadow: upp-variables.$shadow;
  }

  &__box-content {
    display: flex;
    flex-direction: column;
    color: upp-colors.$upp-dark-content-primary;

    &__title {
      @include typography.bodyRegular;
      font-weight: 600;
    }

    &__text {
      @include typography.body;
    }
  }
}
