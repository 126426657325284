@use "@app/theme/variables";
@use "@app/theme/mixins";

.info-card {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  padding: variables.$indentation-lg;
  gap: variables.$indentation-lg;
  background-color: variables.$natera-white;
  border-radius: 6px;
  margin-bottom: 12px;

  &--notification {
    width: 100%;
    .notification__container {
      margin: unset;

      .notification__content {
        padding: variables.$indentation-lg variables.$indentation-lg
          variables.$indentation-lg 0;

        .notification__desc {
          p {
            margin: 8px 0 0;
            @include mixins.text;
          }
        }
      }
    }
  }
  &--edit-button {
    button {
      width: 82px;
      height: 44px;
    }
  }

  &--header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0;

      @media (min-width: variables.$upp-tablet-width) {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &--content {
    display: grid;
    grid-auto-flow: row;
    align-items: start;
    gap: variables.$indentation-lg;
    width: 100%;
  }
}
