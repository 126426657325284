@use "@natera/theme/upp/typography";
@use "@app/theme/variables";
@use "@app/theme/mixins";

.contact-details {
  width: 100%;
  height: 100%;

  &__content {
    &--sign-up {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 16px 10px 0;
      gap: variables.$indentation-sm;

      i {
        display: inline-block;
      }
    }

    &--title {
      display: flex;
      flex-direction: row;
      align-items: center;

      h4 {
        @include typography.subtitle;
        margin: 10px 0;

        @media (min-width: variables.$upp-tablet-width) {
          @include typography.header3;
        }
      }
    }

    &--text {
      @include typography.subtitle2;

      .svg {
        display: flex;
        align-items: center;
      }

      @media (min-width: variables.$upp-tablet-width) {
        @include typography.subtitle;
      }
    }

    &--action {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      button {
        width: 100%;
      }
    }
  }
}

.info-card {
  .phone-updated-notification {
    margin: unset;

    .notification__content {
      padding: 0 16px 0 0;
    }
  }

  &--content {
    .display-field {
      padding: 0 !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: variables.$indentation-sm;
    }

    .with-edit {
      justify-content: space-between;
    }

    & > .with-divider ~ .with-divider {
      border-top: 1px solid variables.$upp-notice-border-color;
      margin-top: 14px;
    }

    .identity-provider-warning {
      margin: 0;
      div {
        p {
          margin: 0;
          font-size: 14px;
          line-height: 18px;
          color: variables.$upp-dark-content-color;
        }

        p:first-child {
          margin-bottom: variables.$indentation-sm;
          font-weight: 500;
        }
      }
    }
  }
}
