@use "@app/theme/_variables.scss" as uppVariables;
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/dimensions";

.schedule-a-gis {
  height: 100%;
  margin: 0 10px;
  padding: 10px 20px;
  background-color: colors.$upp-white;

  @media (max-width: dimensions.$upp-mobile-max-width) {
    margin: 0;
    padding: 0;
    width: 100vw;
  }

  .schedule-a-gis__navigation {
    margin-bottom: 42px;

    @media (max-width: dimensions.$upp-mobile-max-width) {
      margin: 0 10px 30px;
    }

    .schedule-a-gis__navigation__header {
      margin-left: 10px;
      margin-bottom: 25px;

      h1 {
        margin-bottom: 0;
        @include typography.header;
        font-weight: 500;

        @media (max-width: dimensions.$upp-mobile-max-width) {
          @include typography.header2;
        }
      }

      p {
        @media (max-width: dimensions.$upp-mobile-max-width) {
          @include typography.bodyRegular;
        }
      }
    }

    .schedule-a-gis__navigation__list {
      list-style-type: none;
      display: flex;
      padding: 0;

      @media (max-width: dimensions.$upp-tablet-max-width) {
        display: initial;
      }
    }
  }

  .schedule-a-gis__info {
    display: flex;
    gap: 28px;
    background-color: uppVariables.$upp-gis-background-color;
    padding: 28px;

    @media (max-width: dimensions.$upp-mobile-max-width) {
      padding: variables.$indentation-lg;
    }

    .schedule-a-gis__info__image {
      max-height: 293px;
      @media (max-width: dimensions.$upp-tablet-max-width) {
        max-height: initial;
      }
    }

    .schedule-a-gis__info__header h2 {
      margin: 0;
      @include typography.header2;
    }

    .schedule-a-gis__info__header p {
      @include typography.bodyRegular;
    }

    @media (max-width: dimensions.$upp-tablet-max-width) {
      flex-direction: column;
    }
  }
}
