@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/dimensions";
@use "@app/theme/_variables.scss" as variables;

.guest-login-page {
  padding: 24px;

  @media (max-width: 400px) {
    padding: 12px;
  }

  background: linear-gradient(
      222deg,
      rgba(219, 243, 248, 0.5) 1.72%,
      rgba(219, 243, 248, 0) 43.14%
    ),
    linear-gradient(
      305deg,
      rgba(239, 250, 236, 0) 36.64%,
      rgba(239, 250, 236, 0.7) 100%
    );
  align-self: center;

  &__container {
    max-width: 400px;

    &__title {
      color: colors.$upp-dark-content-primary;

      h2 {
        @include typography.header2;
        margin-top: 0;
        margin-bottom: 0;
      }

      & > span {
        @include typography.bodyRegular;
        margin-bottom: 16px;
      }
    }

    &__bottom-text {
      @include typography.bodyRegular;
      margin-top: 32px;

      a {
        color: colors.$upp-blue !important;
      }
    }

    &__footer {
      @include typography.body3;
      color: colors.$upp-dark-content-secondary;
      margin-top: 32px;

      p a {
        @include typography.body3;
        text-decoration: underline;
      }

      a {
        color: colors.$upp-blue;
      }
    }

    &__footer-terms {
      margin-top: 24px;
    }
  }

  .form {
    display: block;
    width: 100%;
    margin-top: 16px;

    .form-content {
      padding: 0;

      &:after {
        padding: 0;
      }
    }
  }

  .verify {
    margin-top: 32px;
    margin-bottom: 48px;
  }

  .title__container {
    margin-top: 16px;
    .notification__container {
      border-radius: 8px;
      .content__descripton {
        text-decoration: underline;
      }
    }
  }

  .mdc-form-field.mdc-text-field--focused:not(.mdc-text-field--disabled) {
    background-color: colors.$upp-white;
  }

  .form-field
    .mdc-form-field.mdc-text-field
    .mdc-text-field__input::placeholder {
    color: variables.$dark-secondary;
  }
}
