@use "@app/theme/_variables.scss" as uppVariables;
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/dimensions";
@use "@app/theme/mixins";

.popUp-dialog {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      max-width: 390px;
      width: 100%;
      max-height: 100%;

      @include mixins.mobile {
        position: fixed;
        bottom: 0;
        right: auto;
        border-radius: 12px 12px 0 0;
      }
    }
  }
  &__content {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    &__header {
      margin-top: 0;
    }

    &__button {
      text-align: left;
      margin-bottom: 10px;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }
}

.popUp-dialog.mobile {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s, transform 0.6s;
}

.popUp-dialog.mobile.animate-up {
  bottom: 0;
  opacity: 1;
  transform: translateY(0);
}

.popUp-dialog.mobile.animate-down {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s, transform 0.6s;
}
