@use "@app/theme/_variables.scss"as variables;
@use "@app/theme/mixins" as mixins;

.notification {
  &__container {
    display: flex;
    background-color: variables.$upp-neutral-cream-color;
    transition: height 1500ms ease;
    overflow-y: hidden;
    opacity: 1;
    border: 1px solid variables.$upp-notice-border-color;
    border-radius: 8px;
    margin: 24px 0 16px 0;

    @include mixins.mobile {
      margin: 16px;
    }

    & > i {
      margin: 16px;
    }
  }

  &__content {
    padding: 16px 16px 16px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    align-self: center;

    color: variables.$upp-dark-content-color;

    & > div {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-weight: 500;
  }

  &__actions {
    margin-top: 8px;
    a {
      cursor: pointer;
    }
  }
}

.title__container .notification__container,
.public-main .mobile-view__container .notification__container {
  margin: 0 0 16px 0;
}

.notification__container_info {
  background-color: variables.$upp-system-info;
}

.notification__container_warning {
  background-color: variables.$upp-system-warning;
}

.notification__container_error {
  background-color: variables.$upp-system-error;
}

.notification__container_success {
  background-color: variables.$upp-system-success;
}

.notification__container_note {
  background-color: variables.$upp-system-note;
}
