.avatar-logo {
  &__container {
    height: 32px;
    width: 32px;
    margin: 0 8px 0 0;
    border-radius: 50%;
    background-color: rgba(30, 180, 238, 1);
  }

  &__image {
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }
}
