@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.whats-next-section {
  &__title {
    @include typography.header3;
    color: colors.$upp-dark-content-primary;
  }

  &__description {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-primary;
  }

  &__step {
    margin-bottom: 16px;
    &__header {
      @include typography.subtitle;
      color: colors.$upp-dark-content-primary;
      font-weight: 600;
    }
    &__content {
      @include typography.bodyRegular;
      color: colors.$upp-dark-content-primary;
    }
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }
}
