@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/components/material/button/mixins";

.send-email__container {
  margin: 0 auto;
  text-align: center;
  max-width: 400px;

  .email-notification {
    margin: 0;
    opacity: 0;
    transition: opacity 200ms linear;

    &.email-notification--visible {
      opacity: 1;
    }

    div {
      text-align: left;
      padding: 16px 16px 16px 0;
      color: rgba(0, 0, 0, 0.56);

      p {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: 0;
      }

      p:first-child {
        font-weight: 500;
        margin-bottom: 8px;
      }
    }
  }

  .image-container {
    margin: 20px 0 16px;
  }

  .description-container {
    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      margin: 0 0 16px 0;
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;

      span {
        font-weight: 500;
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      @include mixins.navy-blue-button;
    }

    & > a {
      width: 100%;
      display: inline-block;
      padding: 12px 0;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: colors.$upp-white;
      text-align: center;
      background-color: colors.$upp-blue;
      border-radius: 12px;
    }
  }
}
