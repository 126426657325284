@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/dimensions";
@use "@app/theme/mixins";

@mixin container {
  width: 100%;
  background-color: colors.$upp-white;
  border-radius: 8px;
  box-sizing: border-box;
  padding: variables.$indentation-xlg;

  @media (min-width: dimensions.$upp-tablet-min-width) {
    max-width: 600px;
    height: auto;
    margin: 0 auto;
    margin-top: 56px;
    box-shadow: variables.$shadow;
    padding: 56px;
  }

  @content;
}

@mixin inner-box {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 8px;

  @content;
}

@mixin actionButtons {
  margin-top: variables.$indentation-lg;
  display: flex;
  flex-direction: column;
  gap: variables.$indentation-lg;

  @media (min-width: dimensions.$upp-tablet-min-width) {
    flex-direction: row-reverse;
    align-items: center;

    button {
      width: 100%;
    }
  }
}

body #root {
  @include mixins.mobile {
    background-color: colors.$upp-white;
  }

  .public-main {
    padding: 0;
    max-width: auto;
  }

  .link {
    cursor: pointer;
  }
}
