@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.multiple-insurance-selector {
  &__item {
    label.form-field-label {
      @include typography.subtitle2;
      white-space: normal;
      color: colors.$upp-dark-content-primary;
    }
  }
}
