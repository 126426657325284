.dropdown-wrapper {
  max-height: 0;
  transition: max-height 2000ms ease-out;
  overflow-y: hidden;

  &--visible {
    max-height: 1000px;
    transition: max-height 2500ms ease-in;
  }
}
