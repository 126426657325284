@use "@natera/theme/upp/variables";

.skip-kit-shipping-dialog {
  ul {
    max-width: 342px;
    padding-left: variables.$indentation-xlg;

    li:last-child {
      margin-top: variables.$indentation-xlg;
    }
  }

  footer {
    padding-bottom: variables.$indentation-md;
  }
}
