@use "@app/theme/_variables.scss" as uppVariables;
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/dimensions";

.renewal-dialog {
  .mdc-dialog__container {
    .mdc-dialog__surface {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      overflow-y: unset;
      border-radius: inherit;

      @include dimensions.laptop {
        max-width: calc(100vw - 30%);
        max-height: calc(100% - 32px);
        border-radius: 24px;
      }

      @include dimensions.desktop {
        max-width: calc(100vw - 50%);
      }

      .mdc-dialog__header {
        padding: 22px 16px;
        background-color: colors.$upp-blue;
        text-align: center;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;

        @include dimensions.laptop {
          border-top-left-radius: 22px;
          border-top-right-radius: 22px;
        }

        .mdc-dialog__cross-button {
          fill: colors.$upp-white;
          margin: 0;

          @include dimensions.tablet {
            margin-right: -30px;
          }
        }
      }

      .mdc-dialog--with-title {
        justify-content: space-around;
      }

      .mdc-dialog__title {
        color: colors.$upp-white;
        padding: 0;
      }

      .mdc-dialog__content {
        overflow-y: auto;
        overflow-x: clip;
        margin-top: 75px;
      }
    }
  }

  &__content {
    padding: 0;

    @include dimensions.laptop {
      padding: 0 150px;
    }

    &__header {
      @include typography.header;
      font-weight: 500;
      margin: 15px 0 10px;
    }

    p {
      margin-top: 0;
    }

    &__header-info,
    &__ensuring-info,
    &__assistance p,
    &__recurringTesting-info,
    &__testimonials-video__text,
    &__resourses ul {
      @include typography.bodyRegular;
    }

    &__assistance b {
      display: block;
      margin: 35px 0 10px;
    }

    &__ensuring,
    &__recurringTesting,
    &__testimonials-title,
    &__resourses__title {
      @include typography.header2;
      margin-bottom: 10px;
    }

    &__recurringTesting-image {
      display: block;
      margin: 0 auto;
    }

    &__resourses__list {
      padding-left: 30px;
    }

    &__assistance-link,
    &__resourses-link {
      text-decoration: underline;
      color: colors.$upp-blue;
      cursor: pointer;
    }

    &__testimonials-video {
      padding: variables.$indentation-lg;
      margin-bottom: variables.$indentation-lg;
      background-color: uppVariables.$upp-neutral-blue;
      border-radius: uppVariables.$indentation-md;

      &__title {
        @include typography.header3;
        margin: 0 0 10px;
      }
    }
  }

  &.mobile {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.6s, transform 0.6s;

    &.animate-up {
      bottom: 0;
      opacity: 1;
      transform: translateY(0);
    }

    &.animate-down {
      opacity: 0;
      transform: translateY(100px);
      transition: opacity 0.6s, transform 0.6s;
    }
  }
}
