@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables" as natera-variables;

.billing-journey-information {
  .step__head-text {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-primary;
    margin: natera-variables.$indentation-lg 0;
  }

  &__calculate-title {
    @include typography.header3;
    color: colors.$upp-dark-content-primary;
    margin-bottom: natera-variables.$indentation-lg;
  }

  &__calculate-image-box {
    text-align: center;
  }

  &__calculate-image {
    height: 168px;
    margin-bottom: natera-variables.$indentation-lg;
    width: 100%;
  }

  &__questions {
    box-shadow: 0px 0px 4px variables.$light-gray-shadow;
    padding: 0px natera-variables.$indentation-lg;
    border-radius: natera-variables.$border-radius-sm;

    &__label {
      @include typography.buttonMedium;
    }
    &__content {
      @include typography.bodyRegular;
      margin: 0 0 natera-variables.$indentation-lg 0;
      ul {
        padding-left: 20px;
      }
    }
  }

  &__tip-section {
    border-left: 3px solid colors.$upp-green;
    &__container {
      margin: natera-variables.$indentation-xxlg 0;
      padding-left: 12px;
    }
    &__title {
      @include typography.subtitle;
      color: colors.$upp-dark-content-primary;
    }

    &__content {
      @include typography.bodyRegular;
      color: colors.$upp-dark-content-primary;
    }
  }

  .insurance-process-section {
    &__title {
      @include typography.header3;
      color: colors.$upp-dark-content-primary;
    }

    &__description {
      @include typography.bodyRegular;
      color: colors.$upp-dark-content-primary;
    }

    &__step {
      margin-bottom: natera-variables.$indentation-lg;
      &__header {
        @include typography.subtitle;
        color: colors.$upp-dark-content-primary;
        font-weight: 600;
      }
      &__content {
        @include typography.bodyRegular;
        color: colors.$upp-dark-content-primary;
      }
    }

    &__icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: natera-variables.$indentation-lg;
    }
  }

  .expansion-panel {
    &:not(:first-child) {
      border-top: 1px solid variables.$prebill-expansion-panel-border-color;
    }

    &__content {
      padding: 0;
    }
  }

  .mdc-deprecated-list-item {
    padding-left: 0;
    padding-right: 0;
  }

  :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item
    .mdc-deprecated-list-item__ripple::before,
  :not(.mdc-deprecated-list-item--disabled).mdc-deprecated-list-item
    .mdc-deprecated-list-item__ripple::after {
    background-color: transparent;
    background-color: var(--mdc-ripple-color, transparent);
  }

  .stepper__actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: natera-variables.$indentation-xxlg;
  }
}
