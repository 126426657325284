@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.faq-info-text {
  margin-bottom: 32px;

  &__needs-help {
    @include typography.bodyRegular;

    p {
      font-weight: bold;
      margin-bottom: 0;
    }

    a {
      color: colors.$upp-blue !important;
    }
  }
}
