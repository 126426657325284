@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;
@use '@natera/material/lib/theme/colors';

.date-range {
  display: flex;
  gap: 8px;

  .mdc-text-field--outlined {
    &:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
          border-color: colors.$primaryBlue;
        }
      }
    }
    input.mdc-text-field__input {
      width: 176px;
      color: colors.$disabledElements;
    }
  }

  .overlay {
    @include mixins.mobile {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: variables.$upp-dark-content-color;
    }

    &--disabled {
      @include mixins.mobile {
        display: none;
      }
    }
  }
}
