@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.unlock__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  .unlock-progress {
    margin: -24px 0 0 0;

    @include mixins.mobile {
      margin: -16px 0 0 0;
      position: relative;
      width: calc(100% + 2 * #{variables.$upp-mobile-padding});
      left: -#{variables.$upp-mobile-padding};
    }
  }

  .unlock-error {
    margin: 8px 0 0;
    display: none;

    .svg {
      display: flex;
    }

    &.reset-error--visible {
      display: flex;
    }

    div {
      padding: 16px 16px 16px 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  .title__container {
    padding-bottom: 0;

    h1 {
      @include mixins.text-title;
      margin: 16px 0;
    }

    span {
      @include mixins.text-bold;
      line-height: 18px;
    }
  }

  .form__container {
    display: flex;

    .form {
      flex-direction: inherit;
    }

    .form-content {
      padding: 0;
      overflow: inherit !important;
      margin-top: 0;

      & .date-field .mdc-text-field__input {
        &::placeholder {
          text-transform: uppercase;
        }
      }

      &::after {
        padding-bottom: 16px;
      }

      a {
        button {
          width: 100%;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .issues-verification__container {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}
