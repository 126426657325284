@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.ncs-information {
  height: 86vh;
  .step__head-text {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-primary;
    margin: 16px 0;
  }

  &__sub-title {
    @include typography.header3;
    color: colors.$upp-dark-content-primary;
    margin-bottom: 16px;
  }

  &__sub-description {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-primary;
    margin-bottom: 16px;
  }

  .stepper__actions {
    &__button {
      width: 100%;
    }
  }
}
