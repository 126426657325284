@use "@natera/theme/upp/typography";

.insurance-confirmation-dialog {
  &__content {
    @include typography.bodyRegular;
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
