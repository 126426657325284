@use "@app/theme/mixins";
@use "@app/theme/_variables.scss" as variables;

.video-page {
  display: flex;
  flex-direction: column;

  .blue-header-content {
    position: static;

    @include mixins.mobile {
      position: absolute;
    }

    .blue-header_cross-content__title {
      margin: 0;
      padding: 24px 24px 16px;

      @include mixins.mobile {
        padding: 35px 16px;
      }
    }

    &__children {
      width: auto;
      flex-grow: 1;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 16px;
    border-radius: 6px;
    position: relative;
    background-color: variables.$natera-white;
    color: variables.$black;

    @include mixins.mobile {
      background-color: variables.$upp-background-color;
      align-items: center;
      margin: unset;
      width: 100%;
      height: 100%;
      padding: unset;
    }

    .video-content {
      display: flex;
      flex-direction: column-reverse;

      @include mixins.mobile {
        flex-direction: column;
      }

      .video-description {
        display: flex;
        flex-direction: column;

        @include mixins.mobile {
          padding: 24px 16px;
        }

        &__title {
          font-size: 20px;
          line-height: 24px;
          color: variables.$black;
          font-weight: 500;
          margin: 0;

          @include mixins.mobile {
            color: variables.$upp-dark-blue;
          }
        }

        &__text {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.001em;
          color: variables.$upp-dark-content-color;
          margin: 16px 0;
        }
      }
    }

    .video-btns {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 343px;

      @include mixins.mobile {
        max-width: unset;
      }

      & > button {
        margin: 16px 0 0;

        @include mixins.mobile {
          margin: 16px 16px 0;
        }
      }
    }
  }

  .notification {
    &__content > p {
      margin-top: 4px;
    }
  }
}
