@use "@natera/theme/upp/typography";
@use "@app/theme/_variables.scss" as variables;
@use "@natera/theme/upp/colors";

.verify-code-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: colors.$upp-white;
  z-index: 3;

  .verify-code-logo {
    padding: variables.$upp-mobile-padding;
    display: flex;
    justify-content: center;
  }

  .verify-code-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
    padding: variables.$upp-mobile-padding;
  }

  h3 {
    @include typography.header2;
    /* or 144% */
    text-align: center;
    color: variables.$black;
  }

  .verify-code-to {
    margin-bottom: 18px;
    @include typography.body;
    /* or 143% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;

    color: variables.$black;
  }

  .verify-code-form {
    width: 100%;

    .mdc-form-field.mdc-text-field {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }

  .verify-code-resend {
    @include typography.body;
    margin: 16px 0;
    margin-bottom: 18px;
    flex-direction: column;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: colors.$upp-gray;

    .update-mobile-number-button {
      margin-bottom: 16px;
    }
  }
}
