@use "@app/theme/variables";
@use "@natera/material/lib/button";
@use "@app/theme/mixins";
@use "@natera/theme/upp/components/material/button/mixins" as theme-mixins;

.sec-action-card-wrapper {
  .sec-action-card {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 24px;

    &__title {
      font-weight: 550;
    }

    &__description {
      font-weight: 400;
    }

    @media (max-width: variables.$upp-mobile-width) {
      font-size: 14px;
      line-height: 18px;
    }

    &__btn {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 0 -16px 0 0;

      @media (max-width: variables.$upp-mobile-width) {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
      }
      button {
        white-space: nowrap;
        @include theme-mixins.navy-blue-button;
      }
    }
  }

  .block-info-card {
    grid-column: 2;

    @include mixins.tablet {
      margin-bottom: variables.$upp-mobile-padding;
    }

    @include mixins.tablet {
      grid-column: 1;
    }

    &__content--vertical {
      .sec-action-card {
        flex-direction: column;
        gap: 16px;
        align-items: start;
      }
    }

    &__image,
    .svg {
      display: flex;
      align-items: center;
    }
  }
}
