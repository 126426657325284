@use "@app/theme/_variables.scss" as uppVariables;
@use "@natera/theme/upp/colors";
@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/dimensions";

.list-item:not(:last-of-type) {
  margin-right: 10px;

  @media (max-width: dimensions.$upp-mobile-max-width) {
    margin: 0 0 10px;
  }
}

.list-item {
  flex: 1;
  max-width: 33.33%;
  border: 1px solid uppVariables.$upp-gis-item-border-color;
  border-radius: variables.$border-radius-sm;
  border-bottom-color: uppVariables.$upp-gis-item-border-bottom-color;
  padding: 20px;

  @media (max-width: dimensions.$upp-tablet-max-width) {
    max-width: initial;
  }

  .link {
    text-decoration: none;
    align-items: center;
    width: 100%;

    @include mixins.focus-visible;

    .list-item__card {
      text-align: center;
    }

    .list-item__text {
      color: colors.$upp-gray;
      @include typography.header3;
      letter-spacing: uppVariables.$upp-letter-spacing;
    }

    .list-item__description {
      color: colors.$upp-gray;
      @include typography.bodyRegular;
      letter-spacing: uppVariables.$upp-letter-spacing;
    }
  }
}
