@use "@app/theme/variables";

.text-message {
  &__wrapper {
    position: relative;
    animation-name: messageAnimation;
    animation-duration: 0.5s;
  }

  &__bubble {
    display: flex;
    flex-direction: column;
    background-color: variables.$natera-white;
    box-shadow: 0 1px 0 0 variables.$neva-text-bubble-shadow;
    padding: 10px;
    border-radius: 14px;
    max-width: 290px;
    box-sizing: border-box;
    margin-right: 15px;
    white-space: pre-wrap;

    @media (max-width: variables.$upp-mobile-width) {
      max-width: 282px;
    }

    & .widget__message-button,
    .widget__message-dropdown {
      margin-top: 12px;
    }

    & ul:not(.rating__list) {
      display: flex;
      flex-direction: column;
    }
  }

  &__with-logo {
    border-radius: 14px 14px 14px 0;
  }

  &__text {
    color: variables.$neva-main-text;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
