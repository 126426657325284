@use "@app/theme/variables";
@use "@app/theme/mixins";

.change-password {
  width: 100%;
  height: 100%;
  margin: 0;

  @include mixins.tablet {
    position: fixed;
    width: calc(100vw - 200px);
  }
  @include mixins.mobile {
    width: inherit;
  }
  .change-password__form::-webkit-scrollbar {
    display: none;
  }

  .change-password__form {
    height: 100%;
    padding: 16px;
    background-color: variables.$natera-white;
    border-radius: 6px;
    margin: 0 variables.$upp-tablet-padding 8px variables.$upp-tablet-padding;
    overflow-y: scroll;
    position: sticky;
    z-index: 1;
    max-height: calc(100vh - 180px);

    &:nth-child(2) {
      margin-top: -45px;
    }

    @include mixins.tablet {
      margin: 0 0 8px 0;

      &:nth-child(2) {
        margin-top: -10px;
      }
    }

    .form {
      height: 100%;

      .form-content {
        padding: 0;

        .passwordField.mdc-form-field.mdc-text-field {
          width: 100%;
        }

        .change-password__form__wrapper {
          gap: 16px;

          .change-password__form__wrapper__title {
            margin: 10px 0 6px 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
          }

          .change-password__form__wrapper__description {
            font-size: 14px;
            line-height: 18px;
          }

          .new-password-container {
            .notification__container {
              margin: 10px 0 0;
            }
          }

          button {
            width: 100%;
          }
        }
      }
    }
  }
}
