@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.account-info-box {
  border: 1px solid colors.$upp-white;
  border-radius: 8px;
  background-color: colors.$upp-white;
  padding: 16px;
  box-shadow: 0px 1px 7px 0px #00000012, 0px 2px 3px 0px #00000008,
    0px 1px 2px 0px #0000000d;
  margin-bottom: 16px;
  margin-top: 16px;
  position: relative;

  &__title {
    @include typography.subtitle;
    color: colors.$upp-dark-content-primary;
  }
  &__description {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-primary;
    margin-top: 4px;
  }

  &__button {
    margin-top: 16px;
    margin-bottom: 52px;
  }
  &__image {
    position: absolute;
    bottom: 0;
    right: 16px;
  }
}
