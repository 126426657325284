@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/components/material/button/mixins" as theme-mixins;

.financial-aid-card {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 22px;

  padding: 16px;
  margin-left: 2px;
  margin-right: 2px;

  border-radius: variables.$border-radius-sm;
  box-shadow: variables.$shadow;

  background: colors.$upp-white;

  box-sizing: border-box;

  .svg.financial-aid-card__icon {
    position: absolute;
    top: 0;
    left: 0;
    color: colors.$upp-navy-blue;
    background: colors.$upp-dark-content-quinary;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: variables.$border-radius-sm 0 variables.$border-radius-sm 0;
  }

  &__image {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__content {
    &__title {
      @include typography.subtitle;
      @media (max-width: dimensions.$upp-mobile-max-width) {
        @include typography.subtitle2;
      }
    }

    &__description {
      @include typography.bodyRegular;
      @media (max-width: dimensions.$upp-mobile-max-width) {
        @include typography.body;
      }
    }

    &__btn {
      @include typography.buttonMedium;
      margin: 0 -16px 0 0;

      @media (max-width: dimensions.$upp-mobile-max-width) {
        @include typography.button;
      }
      button {
        white-space: nowrap;
        @include theme-mixins.navy-blue-button;
      }
    }
  }

  @media (max-width: dimensions.$upp-tablet-max-width) {
    margin-bottom: variables.$indentation-lg;
  }
}
