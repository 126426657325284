@use "@app/theme/_variables.scss" as variables;
@use "@app/theme/_mixins.scss" as mixins;
@use "@natera/theme/upp/_dimensions";

.setup-profile__container {
  display: flex;
  flex-direction: column;

  @include dimensions.tablet {
    margin: 0 auto;
    height: unset;
  }

  @include mixins.mobile {
    margin: 0;
    height: 100%;
  }

  &__content {
    height: 100%;
    background-color: variables.$natera-white;
    padding: 16px 24px;
    margin: 0;

    @include mixins.tablet {
      height: unset;
    }

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      margin: 0;
    }

    .step-number {
      color: variables.$upp-dark-content-color;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      margin: 0;
    }

    &-form__container {
      display: flex;

      .form {
        flex-direction: inherit;
      }

      .form-content {
        padding: 0;
        overflow: inherit !important;

        button {
          margin: 20px 0 60px;
          min-height: 44px;

          @include mixins.mobile {
            margin-bottom: variables.$upp-mobile-bottom-padding;
          }
        }
      }
    }

    .communication-preferences {
      padding: 0;
    }
  }
}
