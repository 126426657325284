@use "@app/theme/mixins";
@use "@app/theme/variables";

.widget__background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-color: variables.$neva-widget-overlay-background;
  backdrop-filter: blur(15px);

  @include mixins.mobile {
    display: none;
  }
}
