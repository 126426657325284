@use "@natera/theme/upp/_typography";
@use "@natera/theme/upp/_variables";
@use "@natera/theme/upp/_colors";
@use "@natera/theme/upp/_dimensions";
@use "@app/theme/variables" as upp_variables;

.date-of-birth-verification__container {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  max-width: upp_variables.$upp-container-max-width;

  @include dimensions.tablet {
    margin: 0 auto;
    height: unset;
  }

  .date-of-birth-verification__progress {
    margin: variables.$indentation-lg 0 0 0;
    width: 100%;

    @include dimensions.desktop {
      margin: variables.$indentation-lg 0 0;
      width: 100%;
    }
  }

  .date-of-birth-verification__wrapper {
    height: 100%;
    background-color: colors.$upp-white;
    box-sizing: border-box;
    display: flex;
    justify-content: start;

    @include dimensions.tablet {
      justify-content: center;
    }
  }

  .date-of-birth-verification__content {
    height: 100%;
    background-color: colors.$upp-white;
    margin: 0;
    max-width: none;
    width: 100%;
    padding: variables.$indentation-md variables.$indentation-lg;

    @include dimensions.tablet {
      width: unset;
      margin: 0;
      max-width: 343px;
      padding: variables.$indentation-lg variables.$indentation-xlg;
    }

    .date-of-birth-verification__title {
      @include typography.header2;
      margin: 0;
    }

    .step-number {
      @include typography.caption;
      color: colors.$upp-dark-content-secondary;
      margin: 0;
    }

    form.date-of-birth-verification__form__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 16px;

      .form-content {
        padding: 0;
        overflow: inherit !important;
        .form-field {
          .mdc-text-field {
            width: 100%;
          }
        }

        button {
          margin-top: 20px;
          width: 100%;
        }
      }
    }

    .date-of-birth-verification__bottom-text {
      text-align: center;
      margin: 0;

      a {
        text-decoration: underline;
        color: colors.$upp-navy-blue;
      }
    }

    .date-of-birth-verification__notification__container {
      margin: variables.$indentation-lg 0 variables.$indentation-sm;
    }

    .date-of-birth-verification__notification__content {
      display: flex;
      align-items: center;
      margin-right: variables.$indentation-sm;

      p {
        margin: 0;
      }
    }
  }
}
