@use "@app/theme/variables";

.close-widget-dialog {
  & .dialog-container {
    text-align: center;
  }

  & .schedule-gc {
    margin-top: 16px;
  }

  & .linkCreated {
    align-items: flex-end;
  }
}
