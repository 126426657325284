.widget-chat {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;

    & .dialog-container {
      width: 350px;
      @media (max-width: 592px) {
        width: calc(100vw - 64px);
      }
    }
  }
}
