@use "@app/theme/variables";
@use "@app/theme/mixins";

.my-results {
  display: flex;
  padding: 16px;
  background-color: variables.$natera-white;
  border-radius: 8px;
  justify-content: space-between;

  &-wrp {
    display: flex;
  }

  &-info {
    margin-left: 10px;
    &-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: variables.$upp-secondary-blue;
    }

    &-details {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: variables.$upp-dark-content-color;
      margin-top: 8px;
    }
  }
}
