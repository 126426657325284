@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables" as upp-variables;

.blue-header {
  padding-bottom: 45px;
  background-color: colors.$upp-blue;
  position: sticky;
  z-index: 0;

  @include mixins.tablet {
    padding-bottom: 10px;
  }

  &-title {
    display: inline-flex;
    align-items: center;
    padding: upp-variables.$indentation-lg;

    @include mixins.tablet {
      padding-left: upp-variables.$indentation-lg;
    }

    .link {
      &:hover {
        color: colors.$upp-white;
      }
      &:focus-visible {
        outline: 2px solid variables.$upp-navigation-border;
        border-radius: variables.$natera-border-radius;
      }
      &:focus-visible {
        outline: 2px solid variables.$upp-navigation-border;
        border-radius: variables.$natera-border-radius;
      }
      .svg {
        margin-right: 8px;
        display: flex;
      }
    }

    &--text {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: colors.$upp-white;
      text-decoration: none;
    }
  }

  &_cross-content {
    padding-left: variables.$upp-mobile-padding * 2;
    display: flex;
    justify-content: space-between;

    @include mixins.mobile {
      padding-left: unset;
      justify-content: center;
    }

    &__title {
      display: inline-flex;
      align-items: center;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      color: colors.$upp-white;
      padding: variables.$upp-mobile-padding;

      @include mixins.mobile {
        text-align: center;
        padding: 0;
        margin: 35px 0;
        position: relative;
      }
    }

    .link,
    button {
      padding: 0;
      position: absolute;
      top: 16px;
      right: 16px;
      margin-left: 8px;
      width: 24px;
      height: 24px;
    }

    svg {
      fill: colors.$upp-white;
    }
  }
}
