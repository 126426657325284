@use "@app/theme/variables";
@use "@natera/material/lib/select/mixins" as select-mixins;
@use "@material/select/select-theme" as select-theme;

.text-message__bubble {
  & .widget__message-dropdown {
    width: 100%;

    @include select-mixins.dropdown-icon-color(
      variables.$upp-input-border-color
    );
    @include select-mixins.outline-color(variables.$upp-input-border-color);
    @include select-mixins.ink-color(variables.$upp-input-border-color);
    @include select-mixins.container-fill-color(variables.$upp-transparent);
    @include select-theme.outline-shape-radius(8px);
    @include select-mixins.outlined-density(-3);
  }
}

.text-message__bubble {
  & .widget__message-dropdown {
    & .mdc-select__anchor {
      @include select-mixins.dropdown-icon-size(24px);
    }
  }
}
