@use "@app/theme/mixins";
@use "@app/theme/variables";

.sample-draw-preference-list {
  .stepper__item-button {
    box-sizing: border-box;
    padding: 15px 0;
    &.mdc-radio-field {
      width: 100%;
      .mdc-radio {
        margin-left: -10px;
      }
    }
  }

  .stepper__item-button:not(:last-of-type) {
    border-bottom: 0.5px solid variables.$natera-grey-border;
  }
}

.stepper .progress-stepper__section .stepper__actions {
  margin-top: 25px;
}
