@use "@app/theme/variables";

.chat-message-dialog {
  & .dialog-container {
    display: flex;
    flex-direction: column;

    &.hidden {
      display: none;
    }
  }

  &__image {
    max-width: 100%;
    border: 1px solid variables.$upp-light-blue;
    border-radius: 10px;
    margin-bottom: 0;
    box-sizing: border-box;

    &:has(+ p) {
      margin-bottom: 16px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    color: variables.$neva-main-text;
    margin: 0;

    & h1 {
      margin: 0 0 16px;
      line-height: 100%;
      font-size: 24px;
    }

    & ul {
      margin: 0;
      padding-left: 26px;
    }
  }

  & .loader-container {
    width: 350px;
    height: 286px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 592px) {
      width: calc(100vw - 64px);
    }
  }
}
