@use "@natera/theme/upp/dimensions";
@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@app/theme/mixins";

@use "../common.scss";

.public-mp-sample-draw-preference {
  @include common.container;

  &__content {
    @include common.inner-box;
    max-width: 440px;

    &-title {
      @include typography.header3;
    }

    &-description {
      @include typography.bodyRegular;
      margin-top: variables.$indentation-sm;
      text-align: left;
    }

    .loading {
      margin: variables.$indentation-lg;
    }

    &-search {
      display: flex;
      gap: variables.$indentation-lg;
      margin: variables.$indentation-xlg 0;

      .zipSelectorForm {
        padding: 0;
        width: 50%;
      }
    }

    &-no-lab {
      @include typography.subtitle;
      color: colors.$upp-dark-content-secondary;
      text-align: center;
      padding: variables.$indentation-xlg;
    }
  }

  .form-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.notification__container {
  margin: variables.$indentation-lg 0;

  @include mixins.mobile {
    margin: variables.$indentation-lg 0;
  }
}
