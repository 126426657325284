@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.multiple-insurance-form {
  .form-content {
    overflow: visible !important;
  }

  &__title {
    @include typography.bodyRegular;
    color: colors.$upp-dark-content-secondary;
    margin-bottom: variables.$indentation-lg;
  }

  .form-field > label {
    margin-bottom: 16px;
  }

  .multiple-insurance-selector__item {
    margin-left: -10px;

    label {
      margin-bottom: 0;
      margin-left: 6px;
    }
  }
}
