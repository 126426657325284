@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/dimensions";
@use "@app/theme/variables" as upp_variables;

@mixin container {
  height: calc(100vh - 60px);
  width: 100%;
  background-color: colors.$upp-white;
  border-radius: 8px;
  box-sizing: border-box;

  @media (min-width: dimensions.$upp-tablet-max-width) {
    max-width: upp_variables.$upp-container-max-width;
    height: auto;
    margin: 0 auto;
    box-shadow: variables.$shadow;
    padding: variables.$indentation-xlg 0;
  }

  @content;
}

@mixin inner-box {
  width: 100%;
  max-width: upp_variables.$upp-inner-box-max-width;
  margin: 0 auto variables.$indentation-xxlg auto;
  box-sizing: border-box;

  @content;
}
