@use "@app/theme/mixins";
@use "@natera/theme/upp/variables";

.details-record {
  width: 100%;
  height: 100%;
  position: relative;

  .test-card {
    @include mixins.mobile {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    @include mixins.tablet {
      margin-bottom: 0;
    }
  }

  .blue-header {
    @include mixins.tablet {
      padding-bottom: 45px;
    }

    @include mixins.mobile {
      padding-bottom: 69px;
      z-index: 1;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    margin: -45px 24px 24px;
    overflow-y: scroll;

    @include mixins.mobile {
      margin: -69px 0 16px;
      overflow-y: scroll;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 32px);
    }

    .natera-layout-grid {
      max-height: calc(100vh - 180px);
      @include mixins.mobile {
        max-height: calc(100vh - 200px);
      }
      .details-record__test-cards {
        @include mixins.mobile {
          display: block;
        }
      }
    }
  }

  &__container::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: variables.$indentation-lg;

    @include mixins.mobile {
      overflow-y: scroll;
      position: sticky;
      z-index: 2;
    }
  }

  &__column {
    gap: variables.$indentation-lg;
    display: flex;
    flex-direction: column;

    @include mixins.tablet {
      gap: unset;
    }

    .test-card {
      margin: unset;
    }
  }

  &__test-cards {
    gap: variables.$indentation-lg;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;

    @include mixins.mobile {
      grid-template-columns: 1fr;
    }
  }

  &__test-information-button {
    display: none;
    @include mixins.mobile {
      display: initial;
    }
  }
  &__test-information-card {
    z-index: 1;
    @include mixins.mobile {
      display: none;
    }
  }

  &__kit-details {
    grid-column: 2;
  }

  &__view-results {
    z-index: 1;

    @include mixins.tablet {
      z-index: 0;
    }
  }
}
