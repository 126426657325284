@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.detail-information-button {
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: colors.$upp-light-blue;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none !important;

  .svg {
    height: 24px;
    width: 24px;
    display: flex;

    * {
      fill: variables.$upp-secondary-blue;
      fill-opacity: 1;
    }
  }
  .svg:first-of-type {
    margin-right: 8px;
  }

  div {
    margin-right: auto;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: variables.$upp-secondary-blue;
  }
}
