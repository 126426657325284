@use "@app/theme/mixins";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables" as natera-variables;

.set-password__container {
  height: 100%;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;

  .set-password-progress {
    margin: (-(natera-variables.$indentation-lg))
      (-(natera-variables.$indentation-lg)) 0;

    @include mixins.mobile {
      margin: (-(natera-variables.$indentation-lg))
        (-(natera-variables.$indentation-lg)) 0;
    }
  }

  .set-password-error {
    margin: natera-variables.$border-radius-sm 0 0;
    display: none;

    .svg {
      display: flex;
    }

    &.set-password-error--visible {
      display: flex;
    }
  }

  h1 {
    @include typography.header2;
    margin-top: natera-variables.$indentation-lg;
    margin-bottom: 0;
  }

  p {
    @include typography.body;
    margin-bottom: 0;
  }
}
