@use "@app/theme/mixins";
@use "@app/theme/variables.scss";

.security-settings {
  &__method {
    color: variables.$upp-dark-content-color;
    display: flex;
    align-items: center;

    .svg {
      height: 1em;
      margin-left: 5px;
      cursor: pointer;
      display: flex;

      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
}

.info-card--content {
  .info-card-field {
    .provider-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .svg {
        display: flex;

        path {
          fill: #99cb3c;
        }
      }
      &-text {
        color: variables.$black;
      }
    }

    .display-field {
      padding: 0;
      .info-card-field__value--text {
        word-break: break-word;

        .provider-description {
          padding-left: 32px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: variables.$upp-letter-spacing-description;
          color: variables.$upp-dark-content-color;
        }

        .provider-description-empty {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: variables.$upp-dark-content-color;
          letter-spacing: 0.001em;
        }

        .notification__container {
          margin: unset;
          .notification__desc {
            p {
              margin: 8px 0 0;
              @include mixins.text;
            }
          }
        }
      }
    }
  }
}
