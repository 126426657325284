@use "@natera/theme/upp/typography";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";

.no-longer-available-card {
  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__box-icon {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__box-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      @include typography.header3;
      margin-top: 24px;
    }
    &__text {
      @include typography.bodyRegular;
      margin-top: 16px;
    }
    &__contact {
      @include typography.body;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 16px;
      span {
        margin: 0;
      }
    }
    &__contact-us {
      text-decoration: underline;
      a {
        color: colors.$upp-blue;
      }
    }
  }

  &__divider {
    background: colors.$upp-drop-shadow;
    height: 1px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;

    display: none;
    @include mixins.mobile {
      display: block;
    }
  }
}
