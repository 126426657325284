@use "@app/theme/variables";
@use "@natera/theme/upp/colors";

.lab-item {
  margin-bottom: 16px;
  display: flex;

  .lab-item__arrow {
    margin-right: 16px;

    .svg {
      display: flex;
      transition: transform 200ms linear;
    }

    &--visible {
      .svg {
        transform: rotate(90deg);
      }
    }
  }

  .lab-item__info {
    padding-bottom: 16px;
    width: 100%;

    .lab-item__info__distance {
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 14px;
      color: colors.$upp-blue;
    }

    .lab-item__info__name {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 18px;
      font-weight: 550;
    }

    .lab-item__info__fulladdress {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    .lab-item__info__wrapper {
      max-height: 0;
      margin-top: 4px;
      overflow-y: hidden;
      transition: max-height 1s linear;

      &--visible {
        max-height: 1000px;
      }

      .lab-item__info__wrapper__phone {
        margin-bottom: 4px;

        a {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: colors.$upp-candy-pink;
        }
      }

      .lab-item__info__wrapper__hours {
        margin-right: 100px;

        .site-hour {
          display: flex;
          justify-content: space-between;

          .site-hour__day {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
          }

          .site-hour__time {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: colors.$upp-dark-content-secondary;
          }
        }

        .site-hour:not(:last-of-type) {
          margin-bottom: 4px;
        }
      }
    }
  }
}
