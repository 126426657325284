@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@material/button";
@use "@natera/theme/upp/colors";

.profile {
  max-width: 1055px;
  margin: 0 auto;
  padding-top: 16px;

  .private-content {
    overflow: hidden;
  }

  @include mixins.mobile {
    padding: variables.$upp-mobile-padding;
    background-color: colors.$upp-white;
  }

  @media (min-width: variables.$upp-mobile-width) and (max-width: variables.$upp-laptop-width) {
    margin-right: variables.$upp-tablet-padding;
  }

  .profile__patient {
    background-color: variables.$upp-light-beige;
    border-radius: 8px;

    @include mixins.mobile {
      width: calc(100% + 32px);
      margin: -16px -16px 0;
      border-radius: unset;
    }

    .patient-card {
      padding: 24px 16px 24px;
      display: flex;
      align-items: center;

      .patient-card__name {
        height: 70px;
        width: 70px;
        min-width: 70px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        border-radius: 100px;
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        color: colors.$upp-gray;
        background-color: colors.$upp-warning;
      }

      .patient-card__info {
        flex-direction: column;
        word-break: break-all;

        @include mixins.mobile {
          &-wrp {
            display: flex;
            flex-direction: column;
          }

          &-bullet {
            display: none;
          }
        }

        h3 {
          width: 100%;
          margin: 0 0 16px;
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
          word-break: break-all;
        }

        p {
          font-size: 14px;
          line-height: 18px;
          align-items: center;
        }

        p:last-of-type {
          margin: 0;
        }

        p:first-of-type {
          & > span {
            margin: 0 8px;
          }
        }

        @media (min-width: variables.$upp-tablet-width) {
          display: flex;
          flex-wrap: wrap;

          h3 {
            font-size: 24px;
            line-height: 32px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
          }

          p:first-of-type {
            display: inline-flex;

            & > span {
              display: inline-flex;
            }
          }
        }
      }
    }
  }

  .profile__routes {
    margin: 16px 0 24px !important;

    .profile__routes__link:not(:last-child) {
      margin-bottom: 8px;
    }

    @include mixins.tablet {
      grid-gap: unset;
    }

    @media (min-width: variables.$upp-tablet-width) {
      margin: 16px 0 !important;
      gap: 16px;

      .profile__routes__link {
        height: fit-content;
        margin: 0 !important;
        grid-column-end: span 6;
      }
    }
  }

  .profile__separator {
    height: 1px;
    background-color: colors.$upp-dark-content-quaternary;
  }

  .profile__footer {
    margin: 32px 0 30px;
    gap: 0;

    @media (min-width: variables.$upp-tablet-width) {
      margin: 16px 0 0;
    }

    .link {
      width: 100%;
      text-decoration: none;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }

    div:not(:last-child) {
      margin-bottom: 24px;

      .link {
        color: colors.$upp-gray;
      }
    }

    &__open-delete-dialog {
      display: none;
      &:is(button) {
        @include button.height(auto);
        padding: 0;
        margin: 0;
        color: colors.$upp-candy-pink;
      }
    }
  }
}

.profile-delete-dialog {
  &__delete-button {
    &:is(button) {
      @include button.container-fill-color(colors.$upp-candy-pink);
    }
  }
}
