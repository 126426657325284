@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";

.acknowledgment__container {
  display: flex;
  flex-direction: column;
  color: colors.$upp-dark-content-primary;

  &__content {
    @include typography.bodyRegular;
    display: flex;
    flex-direction: column;
    gap: variables.$indentation-xlg;
    margin-top: variables.$indentation-lg;

    p {
      margin: 0;
    }
  }

  hr {
    border-top: 1px solid colors.$upp-dark-content-quaternary;
    margin: variables.$indentation-lg 0 0 0;
  }

  &__checkbox {
    display: flex;
    gap: variables.$indentation-sm;
    margin-top: variables.$indentation-lg;

    .mdc-checkbox-field {
      margin-left: -10px;
    }

    &-label {
      @include typography.bodyRegular;
    }
  }

  &__footer {
    margin-top: variables.$indentation-xxlg;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: variables.$indentation-lg;

    button {
      width: 100%;
    }
  }

  .scrollbar-container {
    width: 100%;
  }
}
