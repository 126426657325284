@use "@app/theme/variables";
@use "@natera/theme/upp/typography";

.company-selector {
  &__item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__radio_button.mdc-form-field {
    border-radius: 10px;
    height: 42px;
    width: 100%;

    background-color: variables.$upp-blue-hover !important;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.selected {
      background-color: variables.$upp-blue-selected !important;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;

      color: variables.$upp-navy-blue-primary !important;
      height: 100%;
      width: 100%;
      padding: 0;

      @include typography.body;
    }

    .mdc-radio {
      height: 0 !important;
      width: 0 !important;
      padding: 0;
      opacity: 0;
    }
  }
}
