@use "@app/theme/_variables";
@use "@app/theme/_mixins";

.personal-information {
  width: 100%;
  height: 100%;

  .personal-information__primary,
  .personal-information__other {
    gap: 20px;
    padding: 16px;
    background-color: variables.$natera-white;
    border-radius: 6px;
    margin: 0 variables.$upp-tablet-padding 8px variables.$upp-tablet-padding;

    &:nth-child(2) {
      margin-top: -45px;
    }

    @include mixins.tablet {
      margin: 0 0 8px 0;

      &:nth-child(2) {
        margin-top: -10px;
      }
    }

    @media (min-width: variables.$upp-tablet-width) {
      gap: 26px;
    }

    .info-row {
      & > p {
        margin: 0 0 16px 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;

        @media (min-width: variables.$upp-tablet-width) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      & > p:first-of-type {
        margin-bottom: 16px;
        color: variables.$upp-dark-content-color;
      }
    }
  }

  .personal-information__primary {
    .sign-up-method {
      p:first-child {
        width: 100%;
        display: inline-flex;
        align-items: center;

        span {
          margin-right: 5px;
        }

        .svg {
          display: flex;
          cursor: pointer;

          svg {
            height: 16px;
            width: 16px;
          }
        }
      }

      p:last-of-type {
        display: inline-flex;
        align-items: center;

        .svg {
          margin-right: 8px;
          display: flex;
        }
      }

      .identity-provider-warning {
        margin: 20px 0 0;

        div {
          p {
            margin: 0;
            font-size: 14px;
            line-height: 18px;
            color: variables.$upp-dark-content-color;
          }

          p:first-child {
            margin-bottom: 8px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .edit-btn {
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    & > span {
      margin-right: auto;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;

      @media (min-width: variables.$upp-tablet-width) {
        font-size: 20px;
      }
    }
  }
}
