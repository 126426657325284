@use "@natera/theme/upp/variables";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/dimensions";
@use "../scheduleBloodDraw";

.schedule-blood-draw-personal-info {
  @include scheduleBloodDraw.container;

  .personal-info-content {
    @include scheduleBloodDraw.inner-box;

    &__notification {
      margin: variables.$indentation-lg 0 0 0;

      @media (min-width: dimensions.$upp-tablet-max-width) {
        margin: 0;
      }
    }

    &__title {
      @include typography.header3;
    }

    &__form.form {
      display: block;
      margin-top: variables.$indentation-sm;

      .form-content {
        padding: 0;

        &::after {
          padding-bottom: 0;
        }
      }

      .display-field {
        padding: 16px 0 20px 0;
      }
    }

    &__form-actions {
      padding-bottom: variables.$indentation-lg;
    }

    button {
      width: 100%;
    }

    a.link {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }
}
