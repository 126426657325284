@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";

.success-info-box {
  &__container {
    text-align: center;
    &__title {
      @include typography.header2;
      color: colors.$upp-dark-content-primary;
      margin-top: 16px;
      margin-bottom: 4px;
    }
    &__description {
      @include typography.bodyRegular;
      color: colors.$upp-dark-content-primary;
      margin-top: 4px;
    }
  }
  &__icon {
    width: 100%;
  }
}
