@use "@natera/theme/upp/colors";
@use "@app/theme/_variables.scss" as variables;
@use "@app/theme/mixins";

.sample-draw-preference {
  .sample-draw-preference-list {
    &__item {
      border-bottom: 0.5px solid colors.$upp-dark-content-quaternary;
      box-sizing: border-box;
      padding: 15px 0;

      color: variables.$upp-dark-grey;

      &:first-child {
        padding-top: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      &.mdc-radio-field {
        align-items: flex-start;
      }

      &-title {
        @include mixins.text-bold;
      }
      &-desc {
        @include mixins.text;
      }
    }
  }
}
