@use "@app/theme/variables";
@use "@app/theme/mixins";
@use "@natera/theme/upp/colors";

.blue-header-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: variables.$upp-background-color;

  max-height: calc(100vh - 164px);

  &.blue-header {
    background-color: colors.$upp-blue;
    color: colors.$upp-white;

    height: 108px;
    position: sticky;
    top: 55px;
  }

  @include mixins.mobile {
    max-height: calc(100% - (56px + 56px));
  }

  @include mixins.tablet {
    position: fixed;
    z-index: 3;
  }

  &.fullScreen {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    @include mixins.mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      z-index: 6;
      max-height: unset;

      .blue-header-content__children {
        max-height: unset;
      }
    }
  }

  &__children {
    background-color: variables.$upp-background-color;
    display: grid;
    gap: 16px;
    margin-top: -10px;
    overflow-y: scroll;
    position: sticky;
    z-index: 1;

    @media (min-width: variables.$upp-tablet-width) {
      gap: 8px;
      margin: -45px 24px 0;
    }

    @include mixins.tablet {
      gap: 8px;
      margin: -10px 24px 0;
      width: calc(100vw - 250px);
    }

    @include mixins.mobile {
      margin: -10px 0 0;
      width: inherit;
    }
  }
  &__children::-webkit-scrollbar {
    display: none;
  }
}
