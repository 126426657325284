@use "@app/theme/mixins";
@use "@app/theme/variables";

.stepper-header-progress {
  color: variables.$upp-dark-content-color;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 4px;
}
