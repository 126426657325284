@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/variables";

.signature {
  .step__head {
    &-text {
      margin-top: variables.$indentation-md;

      span {
        @include typography.bodyRegular;
        display: inline-block;
      }
    }
  }
}
