@use "@natera/theme/upp/typography";
@use "@natera/theme/upp/colors";
@use "@natera/theme/upp/variables";

.ncs-payment-failure-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: colors.$upp-white;

  &__content {
    display: flex;
    width: 358px;
    flex-direction: column;
    align-items: center;
  }

  &__icon-container {
    svg {
      width: 120px;
      height: 120px;
    }
  }

  &__message {
    @include typography.header3;
    color: colors.$upp-dark-content-primary;
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
  }

  &__help-text {
    @include typography.bodyRegular;
    color: var(
      --content-dark-highest-emphasis,
      var(--color-content-dark-highest-emphasis, rgba(0, 0, 0, 0.87))
    );
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.016px;

    a {
      color: colors.$upp-blue;
      text-decoration: underline;
    }
  }

  &__button {
    width: 100%;
    max-width: 343px;
    height: 48px;
    @include typography.buttonMedium;
    background-color: colors.$upp-navy-blue;
    color: colors.$upp-white;
    border: none;
    border-radius: variables.$border-radius-md;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(colors.$upp-navy-blue, 10%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(colors.$upp-navy-blue, 0.5);
    }
  }
}
