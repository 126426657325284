@use "@app/theme/mixins";
@use "@app/theme/variables";

.private-main {
  height: 100%;
  width: 100%;
  max-width: variables.$upp-laptop-width;
  margin: 0 auto;
  display: flex;
  flex: 1;
  background-color: variables.$upp-background-color;
  box-sizing: border-box;

  @include mixins.tablet {
    margin-bottom: variables.$upp-mobile-bottom-padding;
  }

  @include mixins.mobile {
    padding: 0;
    margin-bottom: variables.$upp-mobile-bottom-padding;
  }
}

.private-content {
  position: relative;
  margin-right: -24px;
  background-color: variables.$upp-background-color;

  @include mixins.laptop {
    margin-right: 0;
  }

  @include mixins.mobile {
    position: initial;
    margin-right: 0;
    padding-left: unset;
  }

  @include mixins.tablet {
    margin-right: 0;
  }

  flex: 1;

  &--menu-visible {
    padding-left: 230px;
  }
}
