@use "@app/theme/_variables.scss" as variables;

.factor {
  &-container {
    h2 {
      padding: 0;
      margin: 0 0 26px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 145% */

      color: variables.$black;
    }
  }

  &-info {
    background: variables.$upp-neutral-cream-color;
    border: 1px solid variables.$upp-notice-border-color;
    border-radius: 2px;
    padding: variables.$upp-mobile-padding;
    display: flex;
    margin-bottom: 8px;

    &-icon {
      color: variables.$upp-dark-content-color;
      min-width: 22px;
      min-height: 22px;
      margin-right: 16px;
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: variables.$upp-letter-spacing;
      color: variables.$upp-dark-content-color;

      .link {
        color: variables.$upp-dark-content-color;
      }
    }
  }

  &-switch {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__left-label {
      & > h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 8px 0;
      }

      & > div > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 8px 0;
      }

      & > div > ul {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding-left: 22px;
        margin: 0 0 16px 0;
      }
    }

    & > label {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: variables.$upp-dark-grey;
      padding-left: 0;
    }

    & > span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* or 125% */

      color: variables.$black;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;

    & > span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      /* identical to box height, or 229% */

      color: variables.$upp-dark-grey;
    }
  }

  &-item {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    padding: 16px 0;

    &:last-child {
      border-bottom: none;
      padding-bottom: 24px;
    }

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      /* identical to box height, or 164% */

      color: variables.$upp-dark-grey;
    }

    &-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      white-space: normal;
      margin: 8px 0 0 0;

      /* or 133% */

      color: variables.$upp-dark-grey;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > button {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}

.factor-disable-modal {
  &__buttons {
    display: flex;
    flex-direction: column;
    width: 100%;

    & button:last-child {
      margin-top: 16px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: variables.$upp-letter-spacing;
    color: variables.$black;
  }
}

.factor-success-modal {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      height: 76px;
      width: 76px;
      margin: 38px 0;
    }

    button {
      margin-top: 50px;
    }
  }

  &-text {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: variables.$black;
  }
}
