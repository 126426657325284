@use "@natera/theme/upp/dimensions";
@use "../scheduleBloodDraw";

.guest-view-blood-draw {
  @include scheduleBloodDraw.container;

  @media (min-width: dimensions.$upp-tablet-max-width) {
    padding: 0;
  }

  &__content {
    @include scheduleBloodDraw.inner-box;
    padding: 16px 0 24px 0;

    .appointment-details {
      width: 100%;
      padding: 0;
    }

    .appointment-details__actions,
    .appointment-details__content {
      max-width: 100%;
    }

    .appointment-details__actions {
      margin-left: 0;
    }

    .stepper-header-progress {
      display: none;
    }
  }
}
